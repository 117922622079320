define('modules/news/promogrid/js/base.js',["app/module","app/config","jquery","app/util","templates","jquery.mobile.custom"], function(module,config,$,util,templates) {
    return new module({
        name: 'news',
        selectors: '.newsPage',
        remoteData: [config.urls.news],

        routes: [{
            route: 'news/(\\d+)',
            fn: function(hash) {
                var newsId = hash.split('/')[1];
                this.renderNewsDetails(newsId);
            },
            extra: true,
            delay: 300
        },{
            route: 'news',
            fn: 'closeDetails',
            extra: true
        }],

        config: {
            promoCount: 3,
            useCarousel: false,
            showPromoItemsInListing: false
        },

        initialize: function (data) {
            this.$container = $(this.getSelectors());

            this.data = data;
            var shouldUseCarousel = this.getConfig().useCarousel || $(window).width() < 768;
            var promoNewsItems = data.slice(0, this.getConfig().promoCount);

            var regularNewsItems = (this.getConfig().showPromoItemsInListing ? data : data.slice(this.getConfig().promoCount));

            var categories = util.unique($.map(regularNewsItems, function(record){
                return record.category;
            }));
            categories.unshift('All Categories');

            this.$container.find('.newsContainer').html(templates.newspage({
                regularNewsItems: regularNewsItems,
                promoNewsItems: promoNewsItems,
                categories: $.map(categories,function(name){
                   return {name: name};
                }),
                usecarousel: shouldUseCarousel
            }));

            if(data.length <= this.getConfig().promoCount) {
                this.$container.find('.newsListing').remove();
            } else if(categories.length > 2) {
                this.$container.find('.newsListingTabs .tab').on('click', $.proxy(function (e) {
                    e.preventDefault();
                    $(e.currentTarget).siblings().removeClass('active');
                    $(e.currentTarget).addClass('active');
                    this.switchPostListing($(e.currentTarget).text());
                }, this));
                this.$container.find('.newsListingTabs .tab').first().trigger('click');
            } else {
                this.$container.find('.newsListingTabs').remove();
            }

            this.flexCheck();

            if(shouldUseCarousel){
                // init carousel
                this.$container.find('.carousel.slide')
                .carousel({
                    interval: 7500,
                    pause: null
                }).swipeleft(function() {
                    $(this).carousel('next');
                });
                //this is for two news items visible on mobile - currently doesn't work in the best way, is broken on transition
                // if( /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) ) {
                //   this.$container.find('.carousel .item').each(function () {
                //       var next = $(this).next();
                //       if (!next.length) {
                //           next = $(this).siblings(':first');
                //       }
                //       //next.children(':first-child').clone().appendTo($(this));
                //       if (next.next().length > 0) {
                //           next.children(':first-child').clone().appendTo($(this));
                //       } else {
                //           $(this).siblings(':first').children(':first-child').clone().appendTo($(this));
                //       }
                //   });
                // }
            };

            // Resize handler to keep the right side aligned
            //$(window).smartresize($.proxy(this.alignRightScroll,this));

            this.attachEventHandlers();
        },

        onRender: function() {
            if(this['defferedRenderNewsDetail']) {
                this.renderNewsDetails(this['defferedRenderNewsDetail']);
            }
        },

        methods: {

            switchPostListing: function(category) {
                if(category == 'All Categories') {
                    this.$container.find('.newsListingEntryRow').show();
                } else {
                    this.$container.find('.newsListingEntryRow').hide();
                    this.$container.find('.newsListingEntryRow[data-category="' + category + '"]').show();
                }
            },

            renderNewsDetails: function(newsId) {
                if(!this.isReady()) {
                    this.defferedRenderNewsDetail = newsId;
                    return;
                }

                if(!this.newsById){
                    var newsById = {};
                    $.each(this.data, $.proxy(function(index, item){
                        item.originalIndex = index;
                        newsById[item.id] = item;
                    }, this))

                    this.newsById = newsById;
                }

                var newsItem = this.newsById[newsId];
                // TODO: think about the possibility to have 1 or 2 news items of the same category
                if(newsItem){
                    if(!newsItem.prevItem){
                        for(var i= newsItem.originalIndex - 1; i>= 0; i--){
                            var currentItem = this.data[i];
                            if(newsItem.category == currentItem.category){
                                newsItem.prevItem = currentItem;
                                break;
                            }
                        }
                        if(!newsItem.prevItem){
                            for(var i= this.data.length - 1; i> newsItem.originalIndex; i--){
                                var currentItem = this.data[i];
                                if(newsItem.category == currentItem.category){
                                    newsItem.prevItem = currentItem;
                                    break;
                                }
                            }
                        }
                    }
                    if(!newsItem.nextItem){
                        for(var i= newsItem.originalIndex + 1; i< this.data.length; i++){
                            var currentItem = this.data[i];
                            if(newsItem.category == currentItem.category){
                                newsItem.nextItem = currentItem;
                                break;
                            }
                        }
                        if(!newsItem.nextItem){
                            for(var i= 0; i< newsItem.originalIndex; i++){
                                var currentItem = this.data[i];
                                if(newsItem.category == currentItem.category){
                                    newsItem.nextItem = currentItem;
                                    break;
                                }
                            }
                        }
                    }

                    this.$container.closest('body').addClass('locked');
                    this.$container.closest('body').find('.navbarWrap').addClass('hidden').hide();

                    this.$container.find('.newsDetailContainer').html(templates.newsDetailsModal({
                        item: newsItem
                    }));

                    this.$container.find('.carousel.slide').carousel('pause');

                    setTimeout($.proxy(function() {
                        this.$container.find('.newsDetailContainer').addClass('news-details-opened');
                    }, this), 1000);
                };

            },

            closeDetails: function() {
            	if(this.isReady()) {
                    var newsDetailContainer = $('.newsDetailContainer');
                    $('body').removeClass('locked');
                    newsDetailContainer.removeClass('news-details-opened');
                    setTimeout($.proxy(function(){
                        this.$container.find('.newsDetailContainer').empty();
                    },this),1000);
                    $('.navbarWrap').removeClass('hidden').show();
                    this.$container.find('.carousel.slide').carousel('cycle');
                };
            },

            attachEventHandlers: function(){
                this.$container.on("click", ".newsDetailContainer .modal-close", $.proxy(function(e){
                    e.preventDefault();
                    window.history.pushState('news', 'News', '#news');
                    this.closeDetails();
                },this));
            },

            flexCheck: function() {
            	// work around for flex box
            	if ($('.newsGridFlex .newsGridPromoBox').length == 2) {
            		$('.newsGridFlex').addClass('flex-two')
            	}
            },

            // alignRightScroll: function() {
            //     if($('body').hasClass('newsDetailOpen')) {
            //         var elIndex = parseInt($('.newsContainer .sideNav .currentCount').text())-1;
            //         var activeCard = $('.newsContainer .cardWrapper .cardWrap').eq(elIndex);
            //         if(activeCard.length > 0) {
            //             $('.newsContainer').find('.tplWrapper').css('overflow','scroll');
            //             $('.newsContainer').find('.tplWrapper').scrollTop($(activeCard).position().top+1);
            //             setTimeout($.proxy(function(){
            //                 $('.newsContainer').find('.tplWrapper').css('overflow','hidden');
            //             },this),30);
            //         }
            //     }
            // }
        }

    });
});

