define('modules/videobackground/js/base.js',["app/config", "jquery", "app/util", "templates", "app/module"], function (config, $, util, templates,module) {
    return new module({
        name: 'videobackground',
        selectors: '.videoBackground',
        remoteData: [],
        routes: [{
            route: '.*',
            fn: 'resetAndEnableVideos',
            extra: true,
            delay: 250
        }],

        initialize: function () {
            $('body').on({
                bgvideoreset: $.proxy(this.resetAndEnableVideos, this),
                bgvideopause: $.proxy(this.pauseVideos,this)
            });
        },

        methods: {

            resetAndEnableVideos: function () {
                // Stop all videos from playing
                this.pauseVideos();
                // Start the active page video
                $('.pageContainer.active').find(this.getSelectors()).each(function(){
                    $(this).find('video').get(0).play();
                });
            },

            pauseVideos: function() {
                $(this.getSelectors()).each(function () {
                    $(this).find('video').get(0).pause();
                });
            }
        }
    });
});

