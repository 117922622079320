// This is the base/template version of the hooks file override used in projects folder
// Copy this to projects/[projectname]/js/hooks.js and then add logic to the below methods as desired
// TODO - Turn off debug statements
define('hooks',['jquery', 'app/util'], function ($, util) {
    var methods = {
        onDocumentReady: function () {
            // Do nothing
        },
        onAppInitialized: function () {

            // scrolling opacity on faq section
            $(window).on('mousewheel DOMMouseScroll onmousewheel touchmove scroll', $.throttle(10, util.minScrollSpeed($.proxy(function (event) {

                var scrollTop = $(window).scrollTop();
                var windowHeight = $( window ).height();
                var distanceFromBottom = Math.floor($(document).height() - $(document).scrollTop() - $(window).height());
                var docFourth = $(document).height() / 4;
                var docSixth = $(document).height() / 6;

                // scoring page
                if ($('.pageContainer.active').hasClass('scoringPage')) {
                    // scrolling down
                    if (scrollTop <= docSixth) {
                        $('.pageContainer.scoringPage  .custom-background').removeClass('fade-background');
                    }
                    else if (scrollTop <= (docSixth * 2) || scrollTop <= (docSixth * 4)) {
                        $('.pageContainer.scoringPage  .custom-background').addClass('fade-background');
                    }
                    else if (scrollTop <= (docSixth * 5)) {
                        $('.pageContainer.scoringPage  .custom-background').removeClass('fade-background');
                    }
                }

                //home page
                else if ($('.pageContainer.active').hasClass('homePageContainer')) {

                    // scrolling down
                    if (scrollTop <= docFourth) {
                        $('.custom-video.videoBackground').removeClass('fade-video');
                        $('.pageContainer.aboutPage .custom-background').removeClass('fade-background');
                    }
                    else if (scrollTop > docFourth && distanceFromBottom > docFourth) {
                        $('.custom-video.videoBackground').addClass('fade-video');
                        $('.pageContainer.aboutPage  .custom-background').addClass('fade-background');
                    }
                    else if (scrollTop > docFourth && distanceFromBottom < docFourth) {
                        $('.custom-video.videoBackground').removeClass('fade-video');
                        $('.pageContainer.aboutPage  .custom-background').removeClass('fade-background');
                    }
                }

                //winners page
                else if ($('.pageContainer.active').hasClass('winnersPageContainer')) {

                    // scrolling down
                    if (scrollTop <= (windowHeight/2)) {
                        $('.finalistsBg').removeClass('fade-in');
                    }
                    else {
                        $('.finalistsBg').addClass('fade-in');
                    }
                }

                this.lastScrollTop = scrollTop;


            }, this))));


            // Start routing and trigger window resize for skrollr
            $(window).hashchange().trigger('resize'); // TODO - Relocate this, probably to router/nav - moved from js/app, very legacy


        }
    };
    $(document).ready(methods.onDocumentReady);
    return methods;
});

