define('app/contentloader',['jquery','app/configoptions','app/util','jquery.livequery'],function($,config,util){

    return {

        // Basic init methods
        init: function() {
            // if(config.urls.htmlcontent) {
            //     $.getJSON((config.urls ? (config.baseUrl+'/'+config.urls.htmlcontent) : (config.baseUrl+'/data/htmlcontent.json')), $.proxy(this.startLoader,this)).fail(function(error){
            //         console.warn('Failed to load HTML content',error);
            //     });
            // } else {
            //     console.warn('Failed to load htmlcontent JSON file, URL not specified',error);
            // }
        },

        startLoader: function(data) {
            this.data = data;
            this.applySelectors(data);
        },

        // Priority loading queue for deferred requests
        data: [],
        queue: [],
        queuePriorities: {
            home: 1,
            selector: 3,
            route: 5,
            default: 10
        },
        queueTask: null,
        pageLoading: false,
        queueSpeed: 500,

        toggleQueue: function(state) {
            if(state) {
                this.queueTask = setInterval($.proxy(function(){
                    this.queueStep();
                },this),this.queueSpeed);
            } else {
                clearInterval(this.queueTask);
            }
        },

        queueStep: function() {
            //console.debug('Content loader step queue, items in queue:',this.queue)
            this.refreshQueue(); // TODO - Implement a stop/refresh using new values depending on state
            this.refreshQueuedElements();
            var ordered = this.queue.sort(util.dynamicSort('value')).reverse();
            if(!this.pageLoading && ordered.length > 0) {
                this.loadData(ordered[0]);
            }
        },

        // Method that simply pushes more items onto the queue, should only be run one time (for now)
        addToQueue: function(items) {
            this.queue = this.queue.concat(items);
            this.toggleQueue(true);
        },

        // Removes items from the queue based on their URL
        // Stops the queue from running if nothing is left in it
        removeFromQueue: function(url) {
            for(var i = this.queue.length -1; i >= 0 ; i--){
                if(this.queue[i].url == url) {
                    this.queue.splice(i, 1);
                }
            }
            this.pageLoading = false;
            if(this.queue.length == 0) {
                this.stopQueue();
            }
        },

        stopQueue: function() {
            this.toggleQueue(false); // Turn off the queue
            if(window['onContentLoaderComplete']) {
                window.onContentLoaderComplete(true);
            }
        },

        refreshQueue: function() {
            $.each(this.queue, $.proxy(function(index,item){
                this.queue[index].value = this.getItemPriority(item);
            },this));
        },

        getItemPriority: function(item) {
            return this.calculateRoutePriority(item)+this.calculateSelectorPriority(item);
        },

        calculateRoutePriority: function(item) {
            if(window.location.hash.substr(1) == item.route) {
                return this.queuePriorities.route;
            } else if(item.route == 'home') {
                return this.queuePriorities.home;
            } else {
                return 0;
            }
        },

        calculateSelectorPriority: function(item) {
            return ($(item.loadSelector).is(':visible') ? this.queuePriorities.selector : 0);
        },

        // Page Caching
        cache: {},

        getFromCache: function(page) {
            return this.cache[page] || false;
        },
        addToCache: function(page,content) {
            if(!this.getFromCache(page)){
                this.cache[page] = content;
            }
        },

        applySelectors: function(data) {
            this.addToQueue(data);
            var selectors = $.map(this.queue,function(item){
                if($(item.loadSelector).text().length === 0) {
                    $(item.loadSelector).html('&nbsp;');
                }
                return item.loadSelector;
            }).join(',');
            $('body').livequery(selectors,$.proxy(function(element){
                if($(element).is(':visible')) {
                    this.loadData(this.getItemByLoadElement(element));
                } else {
                    var item = this.getItemByLoadElement(element);
                    $(element).attr('data-cl-queue',item.url);
                }
            },this));
        },

        // Data Loading and Parsing
        loadData: function(item) {
            var cachedPage = this.getFromCache(item.url);
            if(cachedPage) {
                var content = this.parseContent(item.fetchSelector,cachedPage);
                this.render(item.loadSelector,content);
            } else {
                //console.debug('fire loading data',item,this.getItemPriority(item))
                this.pageLoading = item.url;
                $.get(config.baseUrl+item.url, $.proxy(function(content){
                    this.addToCache(item.url,content);
                    var content = this.parseContent(item.fetchSelector,content);
                    this.render(item.loadSelector,content);
                    this.removeFromQueue(item.url);
                    this.queueStep();
                },this)).fail($.proxy(function(){
                    console.debug('Contentloader failed to fetch remote source.',item.url); // TODO - Error handling
                    this.removeFromQueue(item.url);
                },this));
            }
        },
        parseContent: function(selector,content) {
            var parsed = false;
            try {
                content = content.replace(/&(?!(amp|apos|quot|lt|gt);)/g, "&amp;");
                parsed = $($.parseXML(content)).find(selector);
            } catch(e) {
                console.warn('Failed to parse HTML/XML content',e);
            }
            return parsed;
        },
        // Element handling and rendering
        render: function(selector,content) {
            if($(selector).length > 0) {
                if($(selector).html() != content) {
                    $(selector).html(content); // TODO - We need to detect and handle potential dynamic populated elements that are handled in config.js livequery
                }
                $(selector).removeAttr('data-cl-queue');
                $(selector).addClass('contentloader-success');
            } else {
                //>>includeStart("debug",pragmas.debug);
                console.warn('Failed to render element from contentloader due to failed selector',selector);
                //>>includeEnd("debug");
            }
        },
        getItemByLoadElement: function(selector) {
            var matched = $.grep(this.data,function(item){
                return $(item.loadSelector).is($(selector));
            });
            if(matched && matched.length > 0) {
                return matched[0];
            }
            return false;
        },

        refreshQueuedElements: function() {
            $('*[data-cl-queue]').each($.proxy(function(i,el){
                var cachedPage = this.getFromCache($(el).attr('data-cl-queue'));
                if(cachedPage) {
                    this.loadData(this.getItemByLoadElement($(el)));
                }
            },this));
        }
    }
});
