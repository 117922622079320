define('modules/pagenav/sidenav/js/base.js',["app/config","jquery","app/util","templates","waypoints"], function(config,$,util,templates) {
    return {
        name: 'sidenav',
        selectors: false,
        remoteData: [],
        routes: [{
            // TODO - This potentially handles the refreshing of Waypoints, where we no longer need the "pagenavupdate" event
            route: '(news|events|judges|finalists|semi-finalists)', // TODO - Ensure/define our routes better
            fn: function(){
                Waypoint.refreshAll();
            },
            extra: true,
            delay: 3500
        }],

        inviews: [],

        initialize: function (data) {

            // Render the template
            $('.sideNavWrap:visible').append(templates.sidenav());

            if ($('.sideNavWrap').hasClass('judgesSideNav')) {
                $('.sideNavWrap.judgesSideNav .sideNav').addClass('judges');
            }

            // Wait until nav element sections have been rendered using custom global event
            $('body').on('pagenavupdate', $.proxy(function(){

                // Clear existing template, remove waypoints
                $('.sideNavWrap').empty();
                this.disableWaypoints();

                // Render the new template
                $('.sideNavWrap:visible').append(templates.sidenav());

                if ($('.sideNavWrap').hasClass('judgesSideNav')) {
                    $('.sideNavWrap.judgesSideNav .sideNav').addClass('judges');
                }

                // Set the total count
                $('.sideNav .totalCount').html($('*[data-pagenav="true"]:visible').length);

                if (!$('.sideNavWrap').hasClass('swap-items')) {
                    this.enableWaypoints();
                }

                // Click handlers
                $('.sideNav .next, .sideNav .prev').on('click', $.proxy(function(e){
                    // TODO fix this, it's hacky


                    if ($('.sideNavWrap').hasClass('swap-items')) {
                        e.preventDefault();

                        var activeIndex = +($('.sideNav .currentCount').text());
                        var lastIndex = +($('.sideNav .totalCount').text());
                        var nextIndex = activeIndex + 1;
                        var prevIndex = activeIndex - 1;

                        var src = $('*[data-pagenav="true"][data-finalist="'+activeIndex+'"] iframe').attr('src');

                        // stop video
                        $('*[data-pagenav="true"][data-finalist="'+activeIndex+'"] iframe').attr('src', src);

                        //next not at the end
                        if ($(e.currentTarget).hasClass('next') && ( activeIndex == lastIndex)) {
                            $('*[data-pagenav="true"][data-finalist="'+activeIndex+'"]').removeClass('active');

                            // TODO maybe not hardcode
                            $('*[data-pagenav="true"][data-finalist="1"]').addClass('active');
                            $('.sideNav .currentCount').text(1);
                        }
                        else if($(e.currentTarget).hasClass('next')) {
                            $('*[data-pagenav="true"][data-finalist="'+activeIndex+'"]').removeClass('active');
                            $('*[data-pagenav="true"][data-finalist="'+nextIndex+'"]').addClass('active');
                            $('.sideNav .currentCount').text(nextIndex);
                        }
                        else if ($(e.currentTarget).hasClass('prev') && activeIndex == 1) {
                            $('*[data-pagenav="true"][data-finalist="'+activeIndex+'"]').removeClass('active');
                            $('*[data-pagenav="true"][data-finalist="'+lastIndex+'"]').addClass('active');
                            $('.sideNav .currentCount').text(lastIndex);
                        }
                        else if($(e.currentTarget).hasClass('prev')) {
                            $('*[data-pagenav="true"][data-finalist="'+activeIndex+'"]').removeClass('active');
                            $('*[data-pagenav="true"][data-finalist="'+prevIndex+'"]').addClass('active');
                            $('.sideNav .currentCount').text(prevIndex);
                        }

                    }


                    else {
                        e.preventDefault();
                        this.disableWaypoints();

                        var panelStyle = true; // TODO - Make this config, add back in offsets and animation duration below for false
                        if(panelStyle && $('.sideNavWrap:visible').attr('data-scrollparent')) {
                            $('.sideNavWrap').parents($('.sideNavWrap:visible').attr('data-scrollparent')).css('overflow','scroll');
                        }

                        setTimeout($.proxy(function(){
                            if ($(e.currentTarget).hasClass('next') && ( $('.sideNav .currentCount').html() == $('.sideNav .totalCount').html())) {
                                util.scrollElToTop($('*[data-pagenav="true"]:visible').eq(0),0,1); // Going from the last item to the first
                            } else if ($(e.currentTarget).hasClass('prev') && $('.sideNav .currentCount').html() == 1) {
                                util.scrollElToTop($('*[data-pagenav="true"]:visible').last(),0,1); // Going from the first item to the last
                            } else if($(e.currentTarget).hasClass('next')) {
                                util.scrollElToTop($('*[data-pagenav="true"]:visible').eq($('.sideNav .currentCount').html()),0,1); // Moving to the next item
                            } else {
                                util.scrollElToTop($('*[data-pagenav="true"]:visible').eq($('.sideNav .currentCount').html()-2),0,1); // Moving to the last item
                            }
                            setTimeout($.proxy(function(){
                                this.enableWaypoints();
                                if(panelStyle) {
                                    $('.sideNavWrap').parents($('.sideNavWrap:visible').attr('data-scrollparent')).css('overflow','hidden');
                                }
                            },this),5);
                        },this),10);
                    }
                },this));
            },this));

            function updateFooter() {
                //$('.sideNav').css('top', window.innerHeight + window.scrollY - 60 + "px");
                //$('.sideNav').css('bottom', '8px');
            }

            var w = $( window ).width();
			// TODO this is MIT2016 specific rework
			/*
            if (w <=767){
                $(document).bind('touchstart', function(e) {
                    if(!$(e.target).parents(".sideNav").length > 0) { // to avoid hiding the footer if it is node 'touched' by the user
                        $('.sideNav').hide();
                    };
                });

                $(document).bind('touchend', function() {
                    //_.defer(function() { // handles waiting until snapback scrolling on mobile devices has finished
                    $('.sideNav').show();
                    // });
                });

                $(document).bind('scroll', function(e) {
                    updateFooter();
                });
            }*/

        },

        enableWaypoints: function() {
            // Add the waypoint listeners
            $('*[data-pagenav="true"]:visible').each($.proxy(function(index,element){
                var inview = new Waypoint({
                    element: $(element),
                    offset: 150,
                    continuous: false,
                    context: ($(element).scrollParent()[0] === document ? $('body') : $(element).scrollParent()),
                    handler: $.proxy(function(direction) {
                        $('.sideNav .currentCount').html($(element).index() + 1);
                    },this)
                });
                this.inviews.push(inview);
            },this));
            Waypoint.refreshAll(); // Refresh all waypoints
        },

        disableWaypoints: function() {
            $.each(this.inviews,function(index,item){
                item.destroy();
            });
            this.inviews = [];
        }

    }
});
