define('modules/process/slidecard/js/base.js',["app/module","app/config","jquery","app/util","templates","swiper.jquery.min","jquery.livequery"], function(module,config,$,util,templates) {
    return new module({
        name: 'process.slidecard',
        selectors: '.process-slidecard-wrap',
        remoteData: [config.urls.process],
        
        config: {
            
        },

        routes: [{
            route: 'home',
            extra: true,
            delay: 2000,
            fn: 'initSwiper' // Route fallback method, will typically be handled via setInterval on onRender method
        }],

        initialize: function (data) {
            this.data = data;
            this.renderTemplates(this.data);
        },

        onRender: function() {
            this.mobileResize(this.data);
            util.setVisibleInterval($(this.getSelectors()),$.proxy(this.initSwiper,this),1000);  // Swiper will not properly initialize until the element is actually visible on DOM, hence this interval
        },

        methods: {

            renderTemplates: function (data) {
                $(this.getSelectors()).append(templates.processslidecard({
                    steps: data.sort(function(a, b) {
                        return a.id - b.id;
                    })
                }));
            },

            initSwiper: function() {

                if(this.swiperInstance) {
                    return;
                }

                this.swiperInstance = new Swiper('.swiper-container-process', {
                    slidesPerView: 'auto',
                    centeredSlides: true,
                    spaceBetween: 45,
                    loop: false,
                    //next lines remove the touch functionality and the mousedrag (simulating touch)
                    simulatesTouch: false,
                    shortSwipes: false,
                    longSwipes: false,
                    onlyExternal: true,
                    prevButton: ".process-swiper-navigate-prev",
                    nextButton: ".process-swiper-navigate-next",
                    pagination: '.process-swiper-pagination',
                    paginationClickable: true,
                    breakpoints: {
                        768: {
                            spaceBetween: 20,
                            simulatesTouch: true,
                            shortSwipes: true,
                            longSwipes: true,
                            onlyExternal: false,
                        }
                    },
                    onInit: function(swiper) {
                        $(swiper.prevButton).fadeOut();
                    },
                    onSlideChangeEnd: function(swiper) {
                        if (swiper.isBeginning) {
                            $(swiper.prevButton).fadeOut();
                        } else if (swiper.isEnd) {
                            $(swiper.nextButton).fadeOut();
                        } else {
                            $(swiper.prevButton).add(swiper.nextButton).fadeIn();
                        }
                    },
                });

            },

            //the purpose of this - the cards have to be with the same height on mobile;
            mobileResize: function(data) {

                var processCard = $('.swiper-container-process .swiper-slide');

                $(window).on('resize', function() {

                    var winW = $(window).width();

                    if (winW < 768) {

                        setTimeout(function() {

                            var finalHeight = 0;

                            //remove any added height at the beginning of the cycle
                            processCard.find('.txt-wrap').css('height', '');

                            processCard.each(function() {
                                var self = $(this),
                                    txtWrap = self.find('.txt-wrap'),
                                    txtWrapHeight = txtWrap.outerHeight();

                                //checking which card has the tallest text container
                                if (txtWrapHeight > finalHeight) {
                                    finalHeight = txtWrapHeight;
                                };
                            });

                            // implementing same height for all cards
                            if (finalHeight > 0) {

                                processCard.each(function() {
                                    var self = $(this),
                                        txtWrap = self.find('.txt-wrap');

                                    txtWrap.css('height', finalHeight + 'px');
                                });

                            };

                        }, 800);

                    } else {

                        //removing any specifically set up height for desktop
                        processCard.each(function() {
                            var self = $(this),
                                txtWrap = self.find('.txt-wrap');

                            txtWrap.css('height', '');
                        });
                    };
                });
            },
        }
    });
});

