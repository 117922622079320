define('modules/timeline/classic/js/base.js',["app/module","app/config","jquery","app/util","templates","bootstrap/tooltip","bootstrap/carousel"], function(module,config,$,util,templates) {
    return new module({
        name: 'timeline',
        selectors: '.timelineSection',
        remoteData: [(config.urls.timeline)],

        routes: [{
            route: 'timeline',
            fn: function() {
                $('.timelinePoint.first').tooltip('show');                    
                $('.timelinePoint.active').click();
            },
            extra: true,
            delay: 2500
        }],

        config: {
            showOverview: true,
            abbMonths: false
        },

        initialize: function(data) {
        	

            // Create timestamps for each record
            $(data).each(function(index,record){
                record.timestamp = new Date(record.date).getTime();
            });
            // Sort by timestamps
            data = data.sort(util.dynamicSort('timestamp','id'));

            // Bounding
            var startMonth = new Date(new Date(data[0].timestamp).getFullYear(), new Date(data[0].timestamp).getMonth(), 1, 0, 0, 0),
                endMonth = new Date(new Date(data[data.length - 1].timestamp).getFullYear(), new Date(data[data.length - 1].timestamp).getMonth() + 1, 0, 0, 0, 0),
                startExtra = data[0].timestamp - startMonth.getTime(),
                endExtra = endMonth.getTime() - data[data.length - 1].timestamp;


            //var msCount = (data[data.length-1].timestamp-data[0].timestamp),
            //Let the range be the full period
            var msCount = (endMonth.getTime() - startMonth.getTime()),
                msIncrement = 100/msCount;
            
            // set the active record
            var now = new Date().getTime();
            var isActiveSet = false;
            $.each(data,function(index,item){
                if(!isActiveSet && index == data.length-1) {
                    item.active = true;
                }
                else if(!isActiveSet && now < item.timestamp){
                    item.active = true;
                    isActiveSet = true;
                }
            });

            var timelinePoints = this.getTimelinePoints(data, startMonth, endMonth, msIncrement);
            //calculated the periods
            var timelinePeriods = this.getTimelinePeriods(data);
            var firstConnectLineWidthPercentage = (timelinePoints[0].timestamp - startMonth.getTime()) * msIncrement;
            // Render the template
            $('.timelineSection').append($(templates.timeline({
                timelinePoints: data,
                firstConnectLineWidthPercentage: firstConnectLineWidthPercentage,
                showOverview: this.getConfig().showOverview,
                timelinePeriods: timelinePeriods
            })));

            // Give the timeline gutters
            // if($(window).width() > 1024) {
            // 	$('.timeline').css({
            //     //width: (100 - ((startExtra + endExtra) * msIncrement)) + '%',
            //     	width: '100%',
            //     	'margin-left': (startExtra*msIncrement)+'%'
            // 	});
            // }


            // Delay between carousel cycles, if automated
            var carouselAutoDelay = 5000;

            // if the browser height is less than 640px and still desktop
            if (($(window).height() < 640) && ($(window).width() > 768)) {
                //$('.timeline-bottom').css('position', 'absolute');
                //$('.timeline-bottom').css('top', ( $('.timelineInfo').height() + 150) + 'px');
            }

            // Special handling for smaller screens
            if ( $(window).width() <= 768 ) {

                // Adjust the timeline's position
                //$('.timeline-bottom').css('position', 'absolute');
                //$('.timeline-bottom').css('top', ( $('.timelineInfo').height() + 80) + 'px');

                // Force the carousel to cycle
                $('#timelineInfo').carousel({
                    interval: carouselAutoDelay
                });

            }
            
        

            //===== Beginning of carousel slide animation =====//
            $('#timelineInfo').on('slide.bs.carousel', function(event) {
				var $eventRelatedTarget = $(event.relatedTarget);
				// TODO tooltips should be visible at all times?
                $('.timeline .timelinePoint').removeClass('active');
                $('.timeline .tooltip').removeClass('active');
                
                $('.timeline .timelinePoint').eq($eventRelatedTarget.index()).addClass('active');
                $('.timeline .tooltip').eq($eventRelatedTarget.index()).addClass('active');

                // Update the current Period information
                $('.timeline-period h3').hide().html( $eventRelatedTarget.data('period') ).fadeIn(550);
                $('.timeline-period h6').hide().html( $eventRelatedTarget.data('period-length') ).fadeIn(550);
                $('.timeline .connectLine, .timeline-calendar li').removeClass('highlight').filter('[data-period*="'+$eventRelatedTarget.data('period')+'"]').addClass('highlight');
                $(".timelineSection .timeline-periods li").removeClass("highlight");
                $(".timelineSection .timeline-periods li[data-period='" + $eventRelatedTarget.data("period") + "']").addClass("highlight");
                
                // Clear other tooltips and show current slide
                $('.timeline .timelinePoint').tooltip('hide');
                $('.timeline .timelinePoint').eq($eventRelatedTarget.index()).attr('data-trigger','manual').tooltip('show');

                // Mobile timeline scrolling
                if($(window).width() <= 768) {
                    var leftPosition = $('.timeline .timelinePoint').eq($eventRelatedTarget.index()).css('left');
                    $('.timeline, .timeline-calendar, .timeline-periods')
                        .css('position','relative')
                        .animate({
                            left: (-parseFloat(leftPosition) * .8)
                        });

                    // Adjust timeline position on mobile screens
                    setTimeout( function() {
                        //$('.timeline-bottom').css('top', ( $('.timelineInfo').height() + 80) + 'px');
                    }, 600);
                }

            }).swiperight(function() {
                $(this).carousel('prev');
            }).swipeleft(function() {
                $(this).carousel('next');
            });


            //===== After carousel slide animation =====//
            $('#timelineInfo').on('slid.bs.carousel', function(event) {

                // Are we on mobile?
                if ( $(window).width() <= 768 ) {


                    var totalCarouselItems = $('#timelineInfo .item').length,
                        currentCarouselItem = $('#timelineInfo .item.active').index() + 1;

                    // Are we on the last slide?
                    if ( totalCarouselItems == currentCarouselItem ) {

                        // Stop the carousel
                        $('#timelineInfo').carousel('pause');

                        // Wait a moment, then return to the first slide
                        setTimeout(function() {
                        
                            $('#timelineInfo').carousel(0);
                            setTimeout(function(){
								// Show the very first tooltip
								$('.timelinePoint.first').tooltip('show');
							}, 1000);
                            
                        }, carouselAutoDelay);

                    }

                };


            });


            // Init tooltips
            $('.timelinePoint').tooltip();

            // Add the indiciator for today's date and show the tooltip
            var nowPosition = (new Date().getTime()-startMonth.getTime())*msIncrement;
            if(nowPosition >= 0) {
                $('<div class="timelineNow"><i class="material-icons">place</i></div>').attr('data-title', 'Today').css('left', nowPosition + '%').appendTo($('.timeline'));
                $('.timelineNow').tooltip({
                    template: '<div class="tooltip tooltipNow"><div class="tooltip-arrow"></div><div class="tooltip-inner"></div></div>'
                });
            }

            // Tooltip listeners
            $('.timelinePoint, .timelineNow').on('show.bs.tooltip',function(){
                if(!$(this).hasClass('active')) {
                    $('.timelinePoint.active').tooltip('hide');
                }
            }).on('hide.bs.tooltip',function(){
                if($(this).hasClass('active')) {
                    //console.log($('.timelinePoint+.tooltip.in').length)
                    //return false; // Do not allow closing on currently viewed point
                } else {
                    // Otherwise ensure the active point is reshown
                    $('.timelinePoint.active').tooltip('show');
                }
            });

            // Init timeline point clickers
            $('.timelinePoint').click(function(e){
                e.preventDefault();
                $('#timelineInfo').carousel($('.timelinePoint').index($(this)));
                /*$('.timelinePoint .tooltip').each(function(){
                	$(this).removeClass('active');
                });
                $('.timelinePoint.active .tooltip').addClass('active');*/
            });

            // Update the init Period information
            $('.timeline-period h3').html( data[0].period );
            $('.timeline-period h6').html( data[0].periodlength );
            $('.timeline .connectLine[data-period="'+data[0].period+'"]').addClass('highlight');
            $(".timelineSection .timeline-periods li[data-period='" + data[0].period + "']").addClass("highlight");

            //===== Init Calendar =====//

            var firstMonth  = new Date(data[0].timestamp),
                lastMonth       = new Date(data[data.length - 1].timestamp),
            // Pointer to the DOM
                calendar        = $('.timeline-calendar ul'),
            // Counts the number of months between our two dates
                monthCount      = 0;

            // Array of month name strings
            if (this.getConfig().abbMonths) {
                var monthNames = ['Jan','Feb','Mar','Apr','May','Jun','Jul','Aug','Sept','Oct','Nov','Dec'];
            }
            else {
                var monthNames = ['January','February','March','April','May','June','July','August','September','October','November','December'];
            }

            // Fetch the first date in the timestamp
            var curMonth = firstMonth;

            // Adjust our last month to offset for final month
            //lastMonth.setMonth(lastMonth.getMonth() + 1);

            // For each month...
            while ( curMonth < endMonth ) {

                // Fetch the month's name
                var curMonthName = monthNames[curMonth.getMonth()];

                // Get any timeline points and periods that are inside this month
                var periods = $.map(data,function(v){
                    var month = new Date(v.timestamp).getMonth();
                    if(month == curMonth.getMonth()) {
                        return v.period;
                    } else {
                        return null;
                    }
                });

                // Append the month to our calendar
                calendar.append('<li data-period="'+periods.join(',')+'">' + curMonthName + '</li>');

                // Increase the current month by 1
                curMonth.setMonth( curMonth.getMonth() + 1 );
                // Increase our month counter
                monthCount++;
            }

            // Finally, adjust the width of our month names evenly
            var borderWidth = $('.timeline-calendar li').css('border-right-width').replace("px", "");

            $('.timeline-calendar li').width( (100 / monthCount) - (borderWidth) + '%' );

            // timeout fixes the bug of the first tool tip
            // showing up in the wrong place
            
            
            // if (($('.timeline').css('margin-left') <= '1%' ) && ($('.timelineContainer .timeline-bottom').css('width') == '100%' )) {
            // 	$('.timelinePoint.first').css('left','1%');
            // }
            
            
            /////////////////////
            // new timeline stuff
            /////////////////////
            var detailTop = parseInt($('.timeline-bottom .timelinePoint.first').css('top'));
        	var w = $(window).width();
        	
        	
        	var resizeTimer;

			$(window).on('resize', function(e) {
				w = $(window).width();
			
				//$('.timeline-bottom .tooltip').fadeOut();
				$('.timeline-bottom .tooltip').hide();
				$('.timelinePoint').tooltip('enable');
				$('.timeline-bottom .tooltip').tooltip('hide');

				clearTimeout(resizeTimer);
				resizeTimer = setTimeout(function() {

			
					if ($('.timeline-bottom').hasClass('overview')) {
						$('.timeline-toggle .overview').trigger('click');
					}
					else {
						$('.timeline-toggle .detail').trigger('click');
					}
		
			  }, 500);

			});

			// Overview Toggle Handlers
        	if(this.getConfig().showOverview) {
                $('.timeline-toggle .detail').click(function(){
                    $(this).css('opacity', 1);
                    $('.timeline-toggle .overview').css('opacity', 0.5);

                    $('.timeline-bottom.overview .tooltip').unbind('click');
                    //$('.timelineInfo').fadeIn();
                    //$('.timelineNav').fadeIn();
                    $('.timelineInfo').show();
                    $('.timelineNav').show();
                    $('.timeline-bottom').removeClass('overview');
                    //$('.timeline-bottom').animate({bottom: ''});
                    $('.timeline-bottom').css('bottom','');

                    $('.timeline-bottom .tooltip').each(function(){

                        $(this).css({ top: detailTop, bottom: '' }).removeClass('overview');
                        $(this).find('.tooltip-arrow').css({height: '', bottom: ''});

                        $(this).hide().css('opacity','');

                    });

                    //$('.timeline-bottom .timelinePoint.first').tooltip('show');

                    // put back active class
                    var activeItem = $('.carousel-inner.timelineInfo div.item.active').index();
                    $('.timelinePoint').eq(activeItem).addClass('active');

                    $('.timelinePoint').tooltip('enable');
                    $('.timelinePoint.active').tooltip('show');




                });

                $('.timeline-toggle .overview').click(function(){


                    $(this).css('opacity', 1);
                    $('.timeline-toggle .detail').css('opacity', 0.5);

                    //$('.timelineInfo').fadeOut();
                    //$('.timelineNav').fadeOut();
                    $('.timelineInfo').hide();
                    $('.timelineNav').hide();


                    if ($('body').hasClass('backend')) {
                        //$('.timeline-bottom').animate({bottom: '20px'}).addClass('overview');
                        //removing animations for now
                        $('.timeline-bottom').css('bottom','20px').addClass('overview');
                    }
                    else {
                        //$('.timeline-bottom').animate({bottom: '60px'}).addClass('overview');
                        //removing animations for now
                        //$('.timeline-bottom').css('bottom','60px').addClass('overview');
                        $('.timeline-bottom').addClass('overview');
                    }

                    //$('.timelinePoint').unbind('mouseenter mouseleave');


                    $('.timeline-bottom .timelinePoint').tooltip('show');
                    $('.timeline-bottom .timelinePoint.first').tooltip('show');

                    $('.timeline-bottom .timelinePoint').each(function(){
                        $(this).removeClass('active');
                    });

                    //var startBtm = parseInt($('.timeline-bottom .tooltip:first').css('bottom'));
                    var startBtm = parseInt(24);
                    var startTop = parseInt($('.timeline-bottom .tooltip:first').css('top'));

                    var newTop;

                    // should be higher than number of bubbles
                    var startZIndex = parseInt(99);

                    $('.timeline-bottom .tooltip').not('.tooltipNow').each(function(){

                        $(this).show().css({opacity:1,zIndex:startZIndex}).addClass('overview');
                        startZIndex--;

                        //var startTop = parseInt($(this).css('top'));
                        //newTop = startTop;
                        var lineHeight = Math.abs(newTop);

                        var curW = $(this).width();
                        var curL = $(this).offset().left;
                        //var curBtm = parseInt($(this).css('bottom'));
                        var curBtm = parseInt(24);
                        //var bubbleHeight = parseInt(($(this).height()) + 20);
                        var bubbleHeight;


                        if ($(this).nextAll('.tooltip').length) {

                            var nextBubble = $(this).nextAll('.tooltip');
                            bubbleHeight = parseInt(($(nextBubble).height()) + 20);

                            var nextW = nextBubble.width();
                            var nextL = nextBubble.offset().left;

                            //if overlap
                            // TODO this is very specific and we'll really need to universalize
                            // bubble height width padding etc
                            if(((w >= 900) && (nextL < (curL + curW))) && (curBtm < 300)) {

                                nextBubble.css({ top: (newTop - bubbleHeight), bottom: '' });
                                newTop = newTop - bubbleHeight;

                                var nextBubbleOffsetTop = nextBubble.offset().top;
                                var timelinePointOffsetTop = $('.timelinePoint').offset().top;

                                //offsets for bottom
                                //constant 41px
                                var testOffset = parseInt(($(nextBubble).height()) - 41);

                                nextBubble.find('.tooltip-arrow').css({height: ((timelinePointOffsetTop -  nextBubbleOffsetTop) - 45) - testOffset, bottom: -Math.abs( ((timelinePointOffsetTop -  nextBubbleOffsetTop) - 45) - testOffset ) });
                            }
                            else if (((w < 899) && (nextL < (curL + curW))) && (curBtm < 220)) {

                                nextBubble.css({ top: (newTop - bubbleHeight), bottom: '' });
                                newTop = newTop - bubbleHeight;

                                var nextBubbleOffsetTop = nextBubble.offset().top;
                                var timelinePointOffsetTop = $('.timelinePoint').offset().top;

                                //offsets for bottom
                                //constant 41px
                                var testOffset = parseInt(($(nextBubble).height()) - 41);

                                nextBubble.find('.tooltip-arrow').css({height: ((timelinePointOffsetTop -  nextBubbleOffsetTop) - 45) - testOffset, bottom: -Math.abs( ((timelinePointOffsetTop -  nextBubbleOffsetTop) - 45) - testOffset ) });

                            }
                            else {

                                newTop = startTop;
                                lineHeight = Math.abs(newTop);

                                //nextBubble.animate({ top: (newTop) });
                                nextBubble.css({ top: '', bottom: (startBtm) });
                                nextBubble.find('.tooltip-arrow').css({height: '', bottom: ''});
                            }
                        }
                        else {
                        }
                    });

                    $('.timelinePoint').tooltip('disable');


                    // Init timeline point clickers
                    $('.timeline-bottom.overview .tooltip').click(function(e){

                        e.preventDefault();
                        //$('.timeline-bottom.overview .tooltip').removeClass('active');
                        //$(this).addClass('active');

                        var ttIndex = parseInt($(this).index('.tooltip.overview'));

                        $('.timelinePoint').eq(ttIndex).trigger('click');

                    });


                });
            }
        	
        	// prizeware
        	$('.db-timeline-overview').click(function () {


				$(this).hide();

				//$('.dashboard-timeline .db-timeline-detail').fadeIn();
				$('.dashboard-timeline .db-timeline-detail').show();

				$('.dashboard-timeline').addClass('overview');

				var parHalfHeight = $('.dashboard-timeline.overview .timelineContainer').css('height');
				$('.dashboard-timeline.overview .timelineContainer .timeline-bottom .timeline').css('height', parHalfHeight);

				//$(window).trigger('resize');
				
				$('.timeline-toggle .overview').trigger('click');


			});

			$('.db-timeline-detail').click(function () {

				$(this).hide();

				//$('.dashboard-timeline .db-timeline-overview').fadeIn();
				$('.dashboard-timeline .db-timeline-overview').show();

				$('.dashboard-timeline').removeClass('overview');

				$('.dashboard-timeline .timelineContainer .timeline-bottom .timeline').css('height', '');

				//$(window).trigger('resize');
				
				$('.timeline-toggle .detail').trigger('click');


			});

        },

        onRender: function() {
            // These two onRender methods below will ensure the firing of refreshTimeline after initialize
            $(window).smartresize($.proxy(this.refreshTimeline,this));
            util.setVisibleInterval(
                $(this.getSelectors()).find('.timeline'),
                $.proxy(this.refreshTimeline,this));
        },

        methods:{
            getTimelinePoints: function(data, firstMonthStartDate, lastMonthEndDate, timestampMultiplicator){
                var beginingTimestamp = firstMonthStartDate.getTime();
                var previousTimelinepoint = null;
                $.each(data, function(index, item){
                    item.leftPercentage = (item.timestamp - beginingTimestamp) * timestampMultiplicator;
                    if(previousTimelinepoint){
                        previousTimelinepoint.connectLineLWidthPercentage = (item.timestamp - previousTimelinepoint.timestamp) * timestampMultiplicator;
                    }
                    previousTimelinepoint = item;
                })

                // set connect line width for the last timeline point
                var lastTimelinepoint = data[data.length-1];
                lastTimelinepoint.connectLineLWidthPercentage = (lastMonthEndDate.getTime() - lastTimelinepoint.timestamp) * timestampMultiplicator;

                return data;
            },

            getTimelinePeriods: function(data){
                var timelinePeriods = [];
                $.each(data,function(index,item){
                    var period = timelinePeriods.find(function(p){
                        return p.name === item.period
                    })
                    if(!period){
                        var tokens = item.periodlength.split("-");

                        period = {
                            name: item.period,
                            startDate: new Date(tokens[0]),
                            endDate: new Date(tokens[1])
                        }

                        timelinePeriods.push(period);
                    }
                });

                // add periods before the first one and after the last up to the beggining/end of the months
                var firstPeriod = timelinePeriods[0];
                var year = firstPeriod.startDate.getFullYear();
                var month = firstPeriod.startDate.getMonth();
                var date = firstPeriod.startDate.getDate();

                if(firstPeriod.startDate.getDate() != 1){
                    timelinePeriods.unshift({
                        name: "",
                        startDate: new Date(year, month, 1, 0, 0, 0),
                        endDate: new Date(year, month, date, 0, 0, -1)
                    })
                }

                var lastPeriod = timelinePeriods[timelinePeriods.length - 1];
                year = lastPeriod.endDate.getFullYear();
                month = lastPeriod.endDate.getMonth();
                date = lastPeriod.endDate.getDate();

                var lastMonthDate = new Date(year, month + 1, 0, 0, 0, 0);
                if(lastPeriod.endDate.getDate() != lastMonthDate.getDate()){
                    timelinePeriods.push({
                        name: "",
                        startDate: new Date(year, month, date, 0, 0, + 1),
                        endDate: lastMonthDate
                    })
                }

                var timelineTotalPeriodLength = timelinePeriods[timelinePeriods.length - 1].endDate.getTime() - timelinePeriods[0].startDate.getTime();
                var timestampMultiplicator = 100 / timelineTotalPeriodLength;

                $.each(timelinePeriods, function(index, period){
                    period.widthPercentage = (period.endDate.getTime() - period.startDate.getTime()) * timestampMultiplicator;
                })

                return timelinePeriods;
            },

            refreshTimeline: function(){
                // Check the positioning of all points and push them apart to ensure no overlap
                var previousTimelinePoint = null;
                $('.timeline .timelinePoint').each(function(index, currentPoint){
                    if(previousTimelinePoint){
                        var overlap = util.checkElementOverlap(previousTimelinePoint, currentPoint);
                        if(overlap){
                            $(currentPoint).css('margin-left', '15px');
                        }
                    }
                    
                    previousTimelinePoint = currentPoint;
                });
            }
        }
    })
});
