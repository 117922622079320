define('modules/navigation/basic/js/base.js',["app/config","jquery","app/util","app/redirect"], function(config,$,util,redirect) {
    return {
        name: 'navigationbasic',
        selectors: false,
        remoteData: [],
        extendable: true,

        lastRoute: null, // Null defaults to home

        routes: [{
            route: '.*',
            fn: function (hash) {
                return this.navigate(hash); // Anything that returns false from the below methods will trigger a 404 page
            },
            priority: 10
        },{
            route: '.*',
            fn: 'trackRoutes',
            extra: true
        },{
            route: 'top',
            fn: function() {
                this.navigate('home');
                util.scrollBodyToTop(500,true);
            },
            priority: 5
        }],

        // TODO - Add our animation options
        options: {
            animationType: false
        },


        initialize: function() {
            if(this.animationType) {
                this.initAnimation();
            }
        },

        initAnimation: $.noop, // TODO - Use our animations

        navigate: function(hash) {
            //>>includeStart("debug",pragmas.debug);
            console.debug('Basic navigation module trigger: ',hash);
            //>>includeEnd("debug");
            hash = this.setDefaultRoute(hash);
            if(hash) {
                var pages = this.getNewPage(hash);
                if (pages) {
                    this.switchPages(pages);
                } else {
                    return false;
                }
            }
        },

        setDefaultRoute: function(hash) {
            // Set the default starting route
            if((!hash && this.lastRoute === null) || (hash.length < 1)) {
                return 'home';
            } else if(this.lastRoute == hash) {
                return false;
            }
            return hash;
        },

        getNewPage: function(hash) {
            // Get our pages
            var currentPage = $('.pageContainer[data-route="'+this.lastRoute+'"]'),
                newPage = $('.pageContainer[data-route="'+hash.split('/')[0]+'"], .contentRedirect[data-route="'+hash.split('/')[0]+'"], *[data-route-section][data-route="'+hash.split('/')[0]+'"]');

            if(!newPage || newPage.length === 0) {
                return false;
            } else if($(currentPage).is($(newPage))) {
                return [];
            } else {
                if($(newPage).attr('data-route-section')) {
                    var sectionPage = $('.pageContainer[data-route="'+$(newPage).attr('data-route-section')+'"]');
                    if(sectionPage.length > 0) {
                        return [currentPage,sectionPage,newPage];
                    } else {
                        return false;
                    }
                } else {
                    return [currentPage,newPage];
                }
            }
        },

        switchPages: function(pages) {
            if(pages.length > 0) {
                var newPage = pages[1].first(),
                    relayPage = pages[2];
                if (newPage.attr('data-redirect-target')) {
                    if ($(newPage.attr('data-redirect-target')).parents('#mainMenu').length > 0) { //&& !$(newPage).parents('#mainMenu').hasClass('open')) {
                        $('body').trigger('updatedashboard', [true, newPage.clone(true, true).removeClass('hidden')]);
                        $('#centerMenuContent').addClass('sidebarPage');
                    } else {
                        redirect.target(newPage, $(newPage.attr('data-redirect-target')));
                    }
                } else {
                    $('.pageContainer').removeClass('active').hide();
                    $('.pageContainer .section').removeClass('active');
                    $(newPage).addClass('active').show().css('opacity', 1);
                    if (relayPage && $(relayPage).length > 0) {
                        $(relayPage).get(0).scrollIntoView();
                    } else {
                        if (typeof $(newPage).attr('data-route-trackscroll') != 'undefined') {
                            $(newPage).scrollParent().scrollTop($(newPage).attr('data-route-trackscroll'));
                        } else {
                        	// TODO config for smooth scroll vs jump to
                            util.scrollBodyToTop(0);
                        }
                    }
                }
            }
        },

        trackRoutes: function(hash) {
            if(typeof $('div[data-route="'+(this.lastRoute || 'home')+'"]').attr('data-route-trackscroll') != 'undefined') {
                $('div[data-route="'+(this.lastRoute || 'home')+'"]').attr('data-route-trackscroll',$('div[data-route="'+(this.lastRoute || 'home')+'"]').scrollParent().scrollTop());
            }
            this.lastRoute = hash;
        }
    }
});
