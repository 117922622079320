define('modules/faqs/expanderswipeless/js/base.js',["app/module","app/config","jquery","app/util","templates","jquery.livequery"], function(module,config,$,util,templates) {
    return new module({
        name: 'faqs.expander',
        selectors: '.faqWrap',
        remoteData: [(config.urls.faqs)],
        
        config: {
            
        },

        routes: [{
            route: 'home',
            extra: true,
            delay: 2000,
            fn: 'initSwiper' // Route fallback method, will typically be handled via setInterval on onRender method
        },{
            route: 'home',
            fn: 'closeDetails',
            extra: true
        }],

        initialize: function (data) {
            this.data = (data || this.data);
            this.renderTemplates(this.data);
        },

        onRender: function() {
            this.attachEventHandlers(this.data);
            util.setVisibleInterval($(this.getSelectors()),$.proxy(this.initSwiper,this));  // Swiper will not properly initialize until the element is actually visible on DOM, hence this interval
        },

		methods: {
			renderTemplates: function (data) {
				$.each(data, function (i, r) {
					data[i].total = data.length; // TODO - Not necessary, we can access parent context length directly in handlebars tpl via dot notation
				});
				$(this.getSelectors()).append(templates.faqsexpanderswipeless({
					records: data.sort(function(a, b) {
                        return a.id - b.id;
                    })
				}));

			},
			
			attachEventHandlers: function (data) {

                var expanderOpening = false;

				// TODO - We need to refactor the scoping on expanderGrid class, as this is used in the app feature
				$('.expanderGrid').on('click', '.inner-col',function(e){

				    e.preventDefault();

                    expanderOpening = true;

					// Set the active attribute on clicked column
					$('.expanderGrid').find('.inner-col').attr('data-active',false);
					$(this).attr('data-active',true);

					var activeCard = $(this).closest('.qa-card'),
						activeColumn = activeCard.closest('.faq-column'),
						expanderGrid = $('.faqWrap .expanderGrid'),
						cardsWrapper = expanderGrid.find('.faq-cards-wrapper');

					var cardsWrapperLeftOffset = cardsWrapper.offset().left,
						activeColumnLeftOffset = activeColumn.offset().left,
						activeColumnLeftTransition = activeColumnLeftOffset - cardsWrapperLeftOffset;

					cardsWrapper.addClass('transformed')
					.css('transform', 'translateX(-' + activeColumnLeftTransition + 'px)');

					expanderGrid.find('.faq-column-active').removeClass('faq-column-active');
					activeColumn.addClass('faq-column-active');

					var qaCardsLength = expanderGrid.find('.faq-column .qa-card').length,
						activeCardNum = $(this).closest('.qa-card').attr('data-card-num');
					activeCardNum = parseInt(activeCardNum);

					// Update and move description row
					var parentRow = $(this).parents('.row').first();

					var h3cont = $(this).find('h3').html(),
						expanderContent = expanderGrid.find('.detailRow .expanderContent');

					expanderContent.html('<h3></h3>');
					expanderContent.find('h3').html(h3cont);
					expanderContent.append($(this).find('.innerSwap').html());

					expanderGrid.find('.detailRow').insertAfter(parentRow);

					var animationDelayCount = 0;

					setTimeout(function() {
						expanderGrid.find('.faq-column-active').addClass('transform-delayed');
						expanderGrid.find('.detailRow').addClass('visible');
						expanderGrid.find('.faq-cards-container-outer').addClass('details-opened');
						expanderGrid.closest('.faqWrap').addClass('detailRow-visible');
						expanderOpening = false;
					}, animationDelayCount);

					// TODO better mobile handling
					var bodyWidth = $('body').width();
					if (bodyWidth < 768) {
						$('body').addClass('locked');
						$('.navbarWrap').addClass('hidden');
					};
				});

				//nav clicks reworked
				$(this.getSelectors()).find('.expanderNav .nav-btn').on('click', function() {
					var self = $(this);

					var expanderGrid = $('.faqWrap .expanderGrid'),
						qaCardsLength = expanderGrid.find('.faq-column .qa-card').length,
						activeIndexEl = expanderGrid.find('.inner-col[data-active="true"]'),
						activeQaCard = activeIndexEl.closest('.qa-card');

					if (self.hasClass('next')) {

						activeIndexEl.attr('data-active', false);

						var nextCard;

						if (activeQaCard.is(':last-child')) {

							var activeCardParent = activeQaCard.closest('.faq-column');

							if (activeCardParent.is(':last-child')) {
								nextCard = expanderGrid.find('.faq-column:first .qa-card:first-child');
							} else {
								nextCard = activeCardParent.next('.faq-column').find('.qa-card:first-child');
							}

							activeCardParent.removeClass('faq-column-active');

						} else {
							nextCard = activeQaCard.next('.qa-card');
						};

						nextCard.find('.inner-col').attr('data-active', true).trigger('click');

					} else if (self.hasClass('prev')) {

						activeIndexEl.attr('data-active', false);

						var prevCard;

						if (activeQaCard.is(':first-child')) {

							var activeCardParent = activeQaCard.closest('.faq-column');

							if (activeCardParent.is(':first-child')) {
								prevCard = expanderGrid.find('.faq-column:last .qa-card:last-child');
							} else {
								prevCard = activeCardParent.prev('.faq-column').find('.qa-card:last-child');
							};

							activeCardParent.removeClass('faq-column-active');

						} else {
							prevCard = activeQaCard.prev('.qa-card');
						};

						prevCard.find('.inner-col').attr('data-active', true).trigger('click');
					};
				});
				
				// close click
				$(this.getSelectors()).find('.expanderNav span.close').on('click', $.proxy(function(e){
				    e.preventDefault();
				    if(!expanderOpening) {
				    	var expanderGrid = $('.faqWrap .expanderGrid');

                        expanderGrid.find('.detailRow').removeClass('visible');
                        expanderGrid.find('.faq-column-active').removeClass('transform-delayed faq-column-active');
                        expanderGrid.find('.faq-cards-container-outer').removeClass('details-opened');
                        expanderGrid.closest('.faqWrap').removeClass('detailRow-visible');

                        $('.faqWrap .faq-cards-wrapper').removeClass('transformed').css('transform', '');

                        $('.detailRow .video-id iframe').attr('src','');
                        expanderGrid.find('.inner-col').attr('data-active', false);
                        $('body').removeClass('locked');
                        $('.navbarWrap').removeClass('hidden');
                    }
				},this));

				var mobileColumnToShow = 1;

				$(this.getSelectors()).find('.js-faq-show-more').on('click', function() {

					var selfParent = $(this).closest('.btn-wrap'),
						faqColumnToShow = $('.faqWrap .faq-column').eq(mobileColumnToShow);

					faqColumnToShow.addClass('shown');

					mobileColumnToShow = mobileColumnToShow + 1;

					if (faqColumnToShow.is(':last-of-type')) {
						selfParent.addClass('hidden');
					};
				});
			},

            initSwiper: function() {

                //TODO - apply comments to explain the functions

                var qaCards = $('.qa-card');

                //necessary to avoid multiple wrapping of elements when navigation multiple times
               	$('.faqWrap .faq-column .qa-card').unwrap();

                for (var i = 0; i < qaCards.length; i+=5) {
                    qaCards.slice(i, i+5).wrapAll('<div class="faq-column"></div>');
                };

                if(($('.faqWrap .faq-column').length) > 2) {
                	$('.faqWrap .faq-cards-container-outer').addClass('larger');
                };

                $('.faqWrap').css('opacity', '1');
            },
            closeDetails: function() {
            	if ($('.faqWrap').hasClass('detailRow-visible')) { 
            		$(this.getSelectors()).find('.expanderNav span.close').trigger('click');
            	}
                
            }
		}
	});
});

