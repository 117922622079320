define('modules/finalists/gridoverlay/js/base.js',['app/config','jquery','templates','skrollr','waypoints'], function(config,$,templates,skrollr) {
    return {
        name: 'finalists',
        selectors: '.finalistContainer',
        // TODO why isn't this working?
        remoteData: [config.urls.finalists],

		routes: [
		{
            route: '(.*)',
            fn: function(hash) {
                this.closeAllOverlays(hash);
            },
            extra: true,
            delay: 3000
        },
        {
            route: 'finalists/(.*)',
            fn: function(hash) {
            	this.goToHash2(hash);
            },
            extra: true,
            delay: 2000
        }],
        initialize: function (data) {
        	var initHtml;

            this.data = (data || this.data);
            this.renderTemplates(this.data);
            //this.finalistOrginalData(this.data);
            //this.finalistTabHandler(this.data);
            //this.refreshFinalistSlider(data);
            this.finalistOverlays(data);


        },

        closeAllOverlays: function (hash) {

        	//remove body class
        	$('body').removeClass('finalistsBody');
        	//set bodyclass
            setTimeout(function(){
            	if ($('.pageContainer.active').hasClass('finalistsPageContainer')) {
					$('body').addClass('finalistsBody');
				}
            }, 1000);

        	var hasHash = hash.split('/')[1];

        	if (!hasHash) {
        		//$('.closeNav')[0].click();
				$('.expanderNav i.close').trigger('tap');
        	}
        },

        goToHash2: function (hash) {
            var scrollToTeam = hash.split('/')[1];

            $(".intro-section-btn[data-scrollto='.section.finalists']").trigger('click');

            var finalistItem = $('.finalist-post[data-teamid="' + scrollToTeam + '"]');
            if (finalistItem) {

                var innerCol = finalistItem.find('.inner-col');
                $(innerCol).trigger('tap');

            }
        },


        goToHash: function (hash) {
        	var scrollToTeam = hash.split('/')[1];

			if (scrollToTeam <= 5) {

    			$('.finalist-page .intro-section.main').hide();
    			$('.finalist-overview').hide();
    			$('.participant-overlay-grid').hide();

    			$('html,body').scrollTop(0);

    			$('.finalist-overlay-grid').fadeIn(500).addClass('open');
    			$('.intro-section-container').fadeIn(500);
    			$('.intro-section-container .finalist').fadeIn(500);


    			setTimeout(function(){
    				$('html, body').animate({
						scrollTop: $('.finalist-container[data-finalist="'+scrollToTeam+'"]').offset().top -50
					}, 1000);
					$('body').trigger('pagenavupdate');
    			}, 2000);


    			$('body').trigger('pagenavupdate');
    			$('.finalist-page .sideNavWrap').fadeIn();
    			$('.finalist-page .closeNav').fadeIn();


			}
			else {

				$('.finalist-page .intro-section.main').hide();
    			$('.finalist-overview').hide();
    			$('.finalist-overlay-grid').hide();

    			$('html,body').scrollTop(0);

    			$('.participant-overlay-grid').fadeIn(500).addClass('open');
    			$('.intro-section-container').fadeIn(500);
    			$('.intro-section-container .participant').fadeIn(500);


    			setTimeout(function(){
    				$('body').trigger('pagenavupdate');
    				$('html, body').animate({
						scrollTop: $('.participant-container[data-id="'+scrollToTeam+'"]').offset().top -50
					}, 1000);
    			}, 2000);

    			$('body').trigger('pagenavupdate');
    			$('.finalist-page .sideNavWrap').fadeIn();
    			$('.finalist-page .closeNav').fadeIn();

			}
        },

        renderTemplates: function (data) {


            $.each(data, function (i, r) {
                data[i].total = data.length;
            });


            $(this.selectors).append(templates.finalists({
                steps: data
            }));

            $(this.selectors).find('.carousel').carousel({
                interval: 10000
            });

            $(this.selectors).on('slide.bs.carousel', function (e) {
                $(e.currentTarget).attr('data-index', $(e.relatedTarget).index());
            });

            $(this.selectors).find('.carousel .item').each(function () {
                var next = $(this).next();
                if (!next.length) next = $(this).siblings(':first');
                next.children(':first-child').clone().appendTo($(this));
            });

            $(this.selectors).find('.carousel .item').each(function () {
                var prev = $(this).prev();
                if (!prev.length) prev = $(this).siblings(':last');
                prev.children(':nth-last-child(2)').clone().prependTo($(this));
            });


        },

        finalistOverlays: function(data) {

        	$('body').trigger('pagenavupdate');


    		$(document).on('click', '.close-overlay', function(){

    			//stop any videos
    			$('.finalist-container iframe').each(function(){
    				var src = $(this).attr('src')
    				$(this).attr('src', src)
    			});

    			if ($('.finalist-overlay-grid').hasClass('open')) {

    				$('.finalist-overlay-grid').fadeOut().removeClass('open');
    				$('.finalist-container').removeClass('active')

					$('body').trigger('pagenavupdate');
                    $('.navbarWrap').removeClass('hidden');
                    $('body').removeClass('locked');


                    if ($( window ).width() <= 768) {

                    	$('html, body').animate({
                            scrollTop: $('.finalistsBg').offset().top -50
                        }, 10, function() {
                            $('.finalistsBg').addClass('fade-in');
						});

					}

    			}



    		});


    		$(document).on('click', '.go-to-finalists', function(e){
    			e.preventDefault();

    			$('.navbarWrap').addClass('hidden');
                $('body').addClass('locked');

    			var parId = $(this).parents('.finalist-col').attr('data-id');
    			$('.finalist-overlay-grid').fadeIn(500).addClass('open');
    			$('.finalist-container[data-finalist="'+parId+'"]').addClass('active');
    			$('.finalist-overlay-grid .sideNav .currentCount').html(parId);

    			$('body').trigger('pagenavupdate');

    			$('.sideNav .currentCount').html(parId);
    			$('.finalist-page .sideNavWrap').fadeIn();
    			$('.finalist-page .closeNav').fadeIn();
    		});

    	}
    }
});
