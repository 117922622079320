define('templates',['handlebars'], function(Handlebars) {

var glob = ('undefined' === typeof window) ? global : window,

Handlebars = glob.Handlebars || require('handlebars');

this["JST"] = this["JST"] || {};

Handlebars.registerPartial("finalistsbios", this["JST"]["finalistsbios"] = Handlebars.template({"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3="function", alias4=container.escapeExpression;

  return "<div class=\"finalist-container col-sm-12\" data-pagenav=\"true\" data-finalist=\""
    + alias4(((helper = (helper = helpers.id || (depth0 != null ? depth0.id : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"id","hash":{},"data":data}) : helper)))
    + "\" data-title=\""
    + alias4(((helper = (helper = helpers.title || (depth0 != null ? depth0.title : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"title","hash":{},"data":data}) : helper)))
    + "\" style=\"background-image:url("
    + alias4(((helper = (helper = helpers.finalistBg || (depth0 != null ? depth0.finalistBg : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"finalistBg","hash":{},"data":data}) : helper)))
    + ");\">\n\n    <div class=\"container-fluid\">\n        <div class=\"row\">\n\n            <div class=\"col-sm-12 finalist-overlay-main-content\">\n                <div class=\"inner\">\n\n                    <div class=\"finalist-content\">\n\n                        <div class=\"nav\">\n                            <div class=\"close-overlay\">\n                                <i class=\"icon-icon-close faq-close-icon\"></i>\n                            </div>\n                        </div>\n\n                        <div class=\"finalist-full-bio animated fadeInDown\">\n                            "
    + ((stack1 = ((helper = (helper = helpers.fullBio || (depth0 != null ? depth0.fullBio : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"fullBio","hash":{},"data":data}) : helper))) != null ? stack1 : "")
    + "\n                            <a href=\""
    + alias4(((helper = (helper = helpers.url || (depth0 != null ? depth0.url : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"url","hash":{},"data":data}) : helper)))
    + "\" target=\"_blank\" class=\"bio-visit-website\">Visit the website</a>\n                        </div>\n\n                    </div>\n\n                </div>\n            </div>\n        </div>\n    </div>\n\n</div>";
},"useData":true}));

Handlebars.registerPartial("finalistspost", this["JST"]["finalistspost"] = Handlebars.template({"1":function(container,depth0,helpers,partials,data) {
    return "active";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3="function", alias4=container.escapeExpression;

  return "<div class=\"item finalistsCarouselItem "
    + ((stack1 = (helpers.ifComp || (depth0 && depth0.ifComp) || alias2).call(alias1,(data && data.index),"==","0",{"name":"ifComp","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\">\n\n    <div class=\"item-third\">\n        <div class=\"finalist-post finalist-visible\" data-id=\""
    + alias4(((helper = (helper = helpers.id || (depth0 != null ? depth0.id : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"id","hash":{},"data":data}) : helper)))
    + "\" data-catid=\""
    + alias4(((helper = (helper = helpers.catId || (depth0 != null ? depth0.catId : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"catId","hash":{},"data":data}) : helper)))
    + "\" data-teamid=\""
    + alias4(((helper = (helper = helpers.teamID || (depth0 != null ? depth0.teamID : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"teamID","hash":{},"data":data}) : helper)))
    + "\">\n            <div class=\"inner-col finalist-col\" data-id=\""
    + alias4(((helper = (helper = helpers.id || (depth0 != null ? depth0.id : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"id","hash":{},"data":data}) : helper)))
    + "\">\n\n                <div class=\"finalists-wrap\">\n                    <div class=\"finalists-pre\">\n                        <h3 class=\"finalistsName\">"
    + alias4(((helper = (helper = helpers.name || (depth0 != null ? depth0.name : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"name","hash":{},"data":data}) : helper)))
    + "</h3>\n                        <h4 class=\"finalistsOrganization\">"
    + alias4(((helper = (helper = helpers.finalistOrganization || (depth0 != null ? depth0.finalistOrganization : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"finalistOrganization","hash":{},"data":data}) : helper)))
    + "</h4>\n                    </div>\n\n                    <div class=\"finalist-thumb\">\n                        <img class=\"finalistphoto\" src=\""
    + alias4(((helper = (helper = helpers.finalistThumb || (depth0 != null ? depth0.finalistThumb : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"finalistThumb","hash":{},"data":data}) : helper)))
    + "\" alt=\"\"/>\n                    </div>\n\n                    <div class=\"finalists-info\">\n                        <span class=\"finalist-summary\">"
    + ((stack1 = ((helper = (helper = helpers.summary || (depth0 != null ? depth0.summary : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"summary","hash":{},"data":data}) : helper))) != null ? stack1 : "")
    + " <span class=\"learn-more go-to-finalists\">Read more about the winning project</span></span>\n                    </div>\n                </div>\n\n            </div>\n        </div>\n    </div>\n\n</div>\n";
},"useData":true}));

Handlebars.registerPartial("judgebox", this["JST"]["judgebox"] = Handlebars.template({"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3="function", alias4=container.escapeExpression;

  return "<a class=\"judgebox\" data-judge=\""
    + alias4(((helper = (helper = helpers.id || (depth0 != null ? depth0.id : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"id","hash":{},"data":data}) : helper)))
    + "\" data-judgegroup=\""
    + alias4(((helper = (helper = helpers.type || (depth0 != null ? depth0.type : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"type","hash":{},"data":data}) : helper)))
    + "\">\n	<div class=\"judgethumb\">\n		<div class=\"img-container\" style=\"background-image: url("
    + alias4(((helper = (helper = helpers.thumbnail || (depth0 != null ? depth0.thumbnail : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"thumbnail","hash":{},"data":data}) : helper)))
    + ");\"></div>\n		<img class=\"img-responsive\" src=\""
    + alias4(((helper = (helper = helpers.thumbnail || (depth0 != null ? depth0.thumbnail : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"thumbnail","hash":{},"data":data}) : helper)))
    + "\" alt=\""
    + alias4(((helper = (helper = helpers.name || (depth0 != null ? depth0.name : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"name","hash":{},"data":data}) : helper)))
    + "\" border=\"0\">\n		<div class=\"name-title-container\">\n			<h5>"
    + alias4((helpers.formatName || (depth0 && depth0.formatName) || alias2).call(alias1,(depth0 != null ? depth0.name : depth0),{"name":"formatName","hash":{},"data":data}))
    + "</h5>\n			<h6 class=\"title\">"
    + alias4(((helper = (helper = helpers.shortDescription || (depth0 != null ? depth0.shortDescription : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"shortDescription","hash":{},"data":data}) : helper)))
    + "</h5>\n		</div>\n	</div>\n</a> ";
},"useData":true}));

Handlebars.registerPartial("judgegroup", this["JST"]["judgegroup"] = Handlebars.template({"1":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = container.invokePartial(partials.judgebox,depth0,{"name":"judgebox","data":data,"indent":"                    ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "");
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3="function", alias4=container.escapeExpression;

  return "<article class=\"judgeGroup text-center border-box\" data-judgegroup=\""
    + alias4(((helper = (helper = helpers.name || (depth0 != null ? depth0.name : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"name","hash":{},"data":data}) : helper)))
    + "\" data-title=\""
    + alias4(((helper = (helper = helpers.name || (depth0 != null ? depth0.name : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"name","hash":{},"data":data}) : helper)))
    + "\">\n    <div class=\"container\">\n        <div class=\"row judge-count-wrap\">\n            <div class=\"col-sm-offset-1 col-sm-10\">\n                <span class=\"judgeCount\">"
    + alias4(container.lambda(((stack1 = (depth0 != null ? depth0.records : depth0)) != null ? stack1.length : stack1), depth0))
    + "</span>\n                <h4 class=\"retired-load_"
    + alias4(((helper = (helper = helpers.cms || (depth0 != null ? depth0.cms : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"cms","hash":{},"data":data}) : helper)))
    + "_h3\">"
    + alias4(((helper = (helper = helpers.descTitle || (depth0 != null ? depth0.descTitle : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"descTitle","hash":{},"data":data}) : helper)))
    + "</h4>\n            </div>\n        </div>\n        <div class=\"row\">\n            <div class=\"col-sm-offset-1 col-sm-10 textColumn retired-load_"
    + alias4(((helper = (helper = helpers.cms || (depth0 != null ? depth0.cms : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"cms","hash":{},"data":data}) : helper)))
    + "_content\">\n                "
    + ((stack1 = ((helper = (helper = helpers.desc || (depth0 != null ? depth0.desc : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"desc","hash":{},"data":data}) : helper))) != null ? stack1 : "")
    + "\n            </div>\n        </div>\n        <div class=\"judgeGridContainer\">\n            <div class=\"judgesGrid row\">\n"
    + ((stack1 = helpers.each.call(alias1,(depth0 != null ? depth0.records : depth0),{"name":"each","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "            </div>\n        </div>\n    </div>\n</article>\n";
},"usePartial":true,"useData":true}));

Handlebars.registerPartial("judgeinfo", this["JST"]["judgeinfo"] = Handlebars.template({"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3="function", alias4=container.escapeExpression;

  return "<div class=\"judgeinfoWrap border-box\" data-judge=\""
    + alias4(((helper = (helper = helpers.id || (depth0 != null ? depth0.id : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"id","hash":{},"data":data}) : helper)))
    + "\" data-pagenav=\"true\">\n	<div class=\"row judge-detail\">\n		\n		<div class=\"col-md-3 col-md-offset-1\">\n			<img src=\""
    + alias4(((helper = (helper = helpers.thumbnail || (depth0 != null ? depth0.thumbnail : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"thumbnail","hash":{},"data":data}) : helper)))
    + "\" class=\"img-responsive\" />\n		</div>\n		<div class=\"col-md-7\">	\n			<h3 class=\"name\">"
    + alias4(((helper = (helper = helpers.name || (depth0 != null ? depth0.name : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"name","hash":{},"data":data}) : helper)))
    + "</h3>					\n			<h4>"
    + alias4(((helper = (helper = helpers.shortDescription || (depth0 != null ? depth0.shortDescription : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"shortDescription","hash":{},"data":data}) : helper)))
    + "</h4>					\n			<p>"
    + ((stack1 = ((helper = (helper = helpers.longDescription || (depth0 != null ? depth0.longDescription : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"longDescription","hash":{},"data":data}) : helper))) != null ? stack1 : "")
    + "</p>\n		</div>\n		\n	</div>		\n</div>			";
},"useData":true}));

Handlebars.registerPartial("judgeinfogroup", this["JST"]["judgeinfogroup"] = Handlebars.template({"1":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = container.invokePartial(partials.judgeinfo,depth0,{"name":"judgeinfo","data":data,"indent":"                ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "");
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3="function", alias4=container.escapeExpression;

  return "<div class=\"row judgeInfoGroup\" data-judgegroup=\""
    + alias4(((helper = (helper = helpers.name || (depth0 != null ? depth0.name : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"name","hash":{},"data":data}) : helper)))
    + "\">\n    <div class=\"col-md-12 judgeTextContainer\">\n        <div class=\"judgeInner\">\n"
    + ((stack1 = helpers.each.call(alias1,(depth0 != null ? depth0.records : depth0),{"name":"each","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "        </div>\n    </div>\n    <div class=\"nav\">\n		<span class=\"backToggle typeIcon visible-xs\" data-judgegroup=\""
    + alias4(((helper = (helper = helpers.name || (depth0 != null ? depth0.name : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"name","hash":{},"data":data}) : helper)))
    + "\">"
    + alias4(((helper = (helper = helpers.name || (depth0 != null ? depth0.name : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"name","hash":{},"data":data}) : helper)))
    + "</span>\n		<div class=\"sideNavWrap judgesSideNav\"></div>\n	</div>\n</div>\n";
},"usePartial":true,"useData":true}));

Handlebars.registerPartial("newslistingentry", this["JST"]["newslistingentry"] = Handlebars.template({"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3="function", alias4=container.escapeExpression;

  return "<div class=\"newsListingEntryRow row\" data-category=\""
    + alias4(((helper = (helper = helpers.category || (depth0 != null ? depth0.category : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"category","hash":{},"data":data}) : helper)))
    + "\">\n    <a href=\"#news/"
    + alias4(((helper = (helper = helpers.id || (depth0 != null ? depth0.id : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"id","hash":{},"data":data}) : helper)))
    + "\">\n        <div class=\"col-md-2 hidden-xs hidden-sm\">\n            <div class=\"news-listing-img-container\">\n                <img src=\""
    + alias4(((helper = (helper = helpers.image || (depth0 != null ? depth0.image : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"image","hash":{},"data":data}) : helper)))
    + "\" class=\"img-responsive\"/>\n            </div>\n        </div>\n        <div class=\"col-sm-12 col-md-8 layout-vertical-center listing-content\">\n            <div class=\"listing-content-inner\">\n                <h4>"
    + alias4(((helper = (helper = helpers.title || (depth0 != null ? depth0.title : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"title","hash":{},"data":data}) : helper)))
    + "</h4>\n                <div class=\"description\">\n                    "
    + ((stack1 = ((helper = (helper = helpers.description || (depth0 != null ? depth0.description : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"description","hash":{},"data":data}) : helper))) != null ? stack1 : "")
    + "\n                </div>\n            </div>\n        </div>\n        <div class=\"col-sm-12 col-md-2\">\n            <span class=\"newsTypeTag\">"
    + alias4(((helper = (helper = helpers.category || (depth0 != null ? depth0.category : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"category","hash":{},"data":data}) : helper)))
    + "</span>\n            <span class=\"newsDate\">"
    + alias4(((helper = (helper = helpers.date || (depth0 != null ? depth0.date : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"date","hash":{},"data":data}) : helper)))
    + "</span>\n        </div>\n    </a>\n</div>";
},"useData":true}));

Handlebars.registerPartial("newspromobox", this["JST"]["newspromobox"] = Handlebars.template({"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3="function", alias4=container.escapeExpression;

  return "<div class=\"newsGridPromoBox\" style=\"background-image:url("
    + alias4(((helper = (helper = helpers.image || (depth0 != null ? depth0.image : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"image","hash":{},"data":data}) : helper)))
    + ");\" data-news=\""
    + alias4(((helper = (helper = helpers.id || (depth0 != null ? depth0.id : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"id","hash":{},"data":data}) : helper)))
    + "\"></div>\n<div class=\"newsBoxInfo\">\n	<a href=\"#news/"
    + alias4(((helper = (helper = helpers.id || (depth0 != null ? depth0.id : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"id","hash":{},"data":data}) : helper)))
    + "\">\n		<span class=\"newsTypeTag\">"
    + alias4(((helper = (helper = helpers.category || (depth0 != null ? depth0.category : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"category","hash":{},"data":data}) : helper)))
    + "</span></span><span class=\"newsDate\">"
    + alias4(((helper = (helper = helpers.date || (depth0 != null ? depth0.date : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"date","hash":{},"data":data}) : helper)))
    + "</span>\n		<h4>"
    + alias4(((helper = (helper = helpers.title || (depth0 != null ? depth0.title : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"title","hash":{},"data":data}) : helper)))
    + "</h4>\n		<h5>"
    + ((stack1 = ((helper = (helper = helpers.description || (depth0 != null ? depth0.description : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"description","hash":{},"data":data}) : helper))) != null ? stack1 : "")
    + "</h5>\n	</a>\n</div>";
},"useData":true}));

Handlebars.registerPartial("newspromocarousel", this["JST"]["newspromocarousel"] = Handlebars.template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3="function", alias4=container.escapeExpression;

  return "            <div class=\"item newsGridCarouselItem "
    + ((stack1 = (helpers.ifComp || (depth0 && depth0.ifComp) || alias2).call(alias1,(data && data.index),"==","0",{"name":"ifComp","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\" data-category=\""
    + alias4(((helper = (helper = helpers.category || (depth0 != null ? depth0.category : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"category","hash":{},"data":data}) : helper)))
    + "\">\n                <a href=\"#news/"
    + alias4(((helper = (helper = helpers.id || (depth0 != null ? depth0.id : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"id","hash":{},"data":data}) : helper)))
    + "\" class=\"item-wrapper-link clearfix\">\n                    <div class=\"img-wrap\">\n                        <div class=\"img-wrap-inner\" style=\"background-image: url("
    + alias4(((helper = (helper = helpers.image || (depth0 != null ? depth0.image : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"image","hash":{},"data":data}) : helper)))
    + ")\"></div>\n                    </div>\n                    <div class=\"details-wrap\">\n                        <div class=\"details-inner-wrap\">\n                            <span class=\"category\">"
    + alias4(((helper = (helper = helpers.category || (depth0 != null ? depth0.category : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"category","hash":{},"data":data}) : helper)))
    + "</span>\n                            <span class=\"date\">"
    + alias4(((helper = (helper = helpers.date || (depth0 != null ? depth0.date : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"date","hash":{},"data":data}) : helper)))
    + "</span>\n                            <h3 class=\"title\">"
    + alias4(((helper = (helper = helpers.title || (depth0 != null ? depth0.title : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"title","hash":{},"data":data}) : helper)))
    + "</h3>\n                            <p class=\"desc\">"
    + ((stack1 = ((helper = (helper = helpers.description || (depth0 != null ? depth0.description : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"description","hash":{},"data":data}) : helper))) != null ? stack1 : "")
    + "</p>\n                            <button class=\"btn btn-primary\">Read More</button>\n                        </div>\n                    </div>\n                </a>\n            </div>\n";
},"2":function(container,depth0,helpers,partials,data) {
    return "active";
},"4":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = (helpers.ifComp || (depth0 && depth0.ifComp) || helpers.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),(data && data.index),"==",0,{"name":"ifComp","hash":{},"fn":container.program(5, data, 0),"inverse":container.program(7, data, 0),"data":data})) != null ? stack1 : "");
},"5":function(container,depth0,helpers,partials,data) {
    var helper;

  return "                <li data-target=\"#newsPromoGridCarousel\" data-slide-to=\""
    + container.escapeExpression(((helper = (helper = helpers.index || (data && data.index)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"index","hash":{},"data":data}) : helper)))
    + "\" class=\"active\"></li>\n";
},"7":function(container,depth0,helpers,partials,data) {
    var helper;

  return "                <li data-target=\"#newsPromoGridCarousel\" data-slide-to=\""
    + container.escapeExpression(((helper = (helper = helpers.index || (data && data.index)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"index","hash":{},"data":data}) : helper)))
    + "\"></li>\n";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {});

  return "<div id=\"newsPromoGridCarousel\" class=\"carousel slide clearfix\" data-ride=\"carousel\">\n    <div class=\"container container-special-visual-inner\"></div>\n    <div class=\"carousel-inner\">\n"
    + ((stack1 = helpers.each.call(alias1,(depth0 != null ? depth0.promoNewsItems : depth0),{"name":"each","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "    </div>\n\n    <ol class=\"carousel-indicators\">\n"
    + ((stack1 = helpers.each.call(alias1,(depth0 != null ? depth0.promoNewsItems : depth0),{"name":"each","hash":{},"fn":container.program(4, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "    </ol>\n</div>\n";
},"useData":true}));

Handlebars.registerPartial("scoringintro", this["JST"]["scoringintro"] = Handlebars.template({"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    return " <div class=\"section pushSection layout-auto-height angleBottomRight\" data-bgimage=\"scoring-intro\">\n	<div class=\"introSection container\" data-title=\"\">\n		<!--<div class=\"load_scoring\">-->\n		<div class=\"row center-height\">\n			<div class=\"col-md-10 col-md-offset-1 content load_scoring_card1\">\n				<h1>Scoring Process</h1>\n				<h2>A careful selection of judges grouped into two categories; the Evaluation Panel and the Selection Committee.</h2>\n				<a class=\"intro-section-btn\" data-scrollto=\".scoreQuestion.section\">Interact with the Rubric<i class=\"icon-icon-arrow-down-rounded\"></i></a>		\n			</div>\n		</div>\n	</div>\n</div>";
},"useData":true}));

Handlebars.registerPartial("scoringitems", this["JST"]["scoringitems"] = Handlebars.template({"1":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3=container.escapeExpression;

  return "                        <div class=\"tick tickTop\" style=\"left:"
    + alias3((helpers.tickLeft || (depth0 && depth0.tickLeft) || alias2).call(alias1,(data && data.index),((stack1 = (depths[1] != null ? depths[1].items : depths[1])) != null ? stack1.length : stack1),{"name":"tickLeft","hash":{},"data":data}))
    + ";\"></div>\n                        <div class=\"tick tickBottom\" style=\"left:"
    + alias3((helpers.tickLeft || (depth0 && depth0.tickLeft) || alias2).call(alias1,(data && data.index),((stack1 = (depths[1] != null ? depths[1].items : depths[1])) != null ? stack1.length : stack1),{"name":"tickLeft","hash":{},"data":data}))
    + ";\">"
    + alias3(((helper = (helper = helpers.score || (depth0 != null ? depth0.score : depth0)) != null ? helper : alias2),(typeof helper === "function" ? helper.call(alias1,{"name":"score","hash":{},"data":data}) : helper)))
    + "</div>\n";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3="function", alias4=container.escapeExpression;

  return "<div class=\"scoreQuestion section pushSection solid-gray-bg scoreQuestionCount\" data-title=\"\">\n    <div class=\"container\">\n        <div class=\"row center-height\">\n            <a class=\"sbtn scoreCommentBtn primaryBlankBtn hidden-xs\" status=\""
    + alias4(((helper = (helper = helpers.statusScore || (depth0 != null ? depth0.statusScore : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"statusScore","hash":{},"data":data}) : helper)))
    + "\">Leave Comments</a>\n            <h3 class=\"hidden-xs\">"
    + alias4(((helper = (helper = helpers.title || (depth0 != null ? depth0.title : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"title","hash":{},"data":data}) : helper)))
    + " <span class=\"scoreRange\">(0-"
    + alias4((helpers.minusOne || (depth0 && depth0.minusOne) || alias2).call(alias1,((stack1 = (depth0 != null ? depth0.items : depth0)) != null ? stack1.length : stack1),{"name":"minusOne","hash":{},"data":data}))
    + ")</span></h3>\n            <div class=\"text-center\">\n                <div class=\"helpText\"><div class=\"inner\"><h5>Help</h5>"
    + alias4(((helper = (helper = helpers.helpText || (depth0 != null ? depth0.helpText : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"helpText","hash":{},"data":data}) : helper)))
    + "</div></div>\n                <div class=\"commentsText text-center\">\n                    <textarea class=\"txtComment\" placeholder=\"Comments (minimum 50 characters): Explain your score and provide feedback to the participant.\"></textarea>\n                    <div class=\"hidden-xs\">\n                        <a class=\"btn\" style=\"display: none\" href=\"#\">Submit Comments</a>\n                    </div>\n                </div>\n            </div>\n            <div class=\"scoreHeader\">\n                <div class=\"scoreCounter hidden-xs\">\n                    <span class=\"current\">"
    + alias4(((helper = (helper = helpers.index || (depth0 != null ? depth0.index : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"index","hash":{},"data":data}) : helper)))
    + "</span>\n                    <span class=\"total\">"
    + alias4(((helper = (helper = helpers.total || (depth0 != null ? depth0.total : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"total","hash":{},"data":data}) : helper)))
    + "</span>\n                </div>\n                <div class=\"scoreCounter scoreNav\">\n                    <span class=\"prev\"><i class=\"icon-arrow-left-full\"></i></span>\n                    <span class=\"next\"><i class=\"icon-arrow-right-full\"></i></span>\n                </div>\n                <div class=\"scoreQuestion\">\n                    <span class=\"hidden\">"
    + alias4(((helper = (helper = helpers.title || (depth0 != null ? depth0.title : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"title","hash":{},"data":data}) : helper)))
    + "</span>\n                    <span class=\"question-text\">"
    + alias4(((helper = (helper = helpers.question || (depth0 != null ? depth0.question : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"question","hash":{},"data":data}) : helper)))
    + "</span>\n                    <span class=\"help-question hidden-xs\">\n						<div class=\"icon-icon-help1\"></div>\n					</span>\n                    <div class=\"icon-icon-close\"></div>\n                </div>\n            </div>\n            <div class=\"scoreDescription\">\n                <span></span>\n            </div>\n            <div class=\"scoreValue\">\n                <span class=\"valfollow\" status=\""
    + alias4(((helper = (helper = helpers.statusScore || (depth0 != null ? depth0.statusScore : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"statusScore","hash":{},"data":data}) : helper)))
    + "\"></span>\n                <span class=\"valBorderLeft hidden-xs\"></span>\n                <span class=\"valBorderRight hidden-xs\"></span>\n            </div>\n            <div class=\"scoreSliderContainer\">\n                <div class=\"sliderTicks\">\n                    <span class=\"visible-xs anchorLow\">"
    + alias4(((helper = (helper = helpers.anchorLow || (depth0 != null ? depth0.anchorLow : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"anchorLow","hash":{},"data":data}) : helper)))
    + "</span>\n"
    + ((stack1 = helpers.each.call(alias1,(depth0 != null ? depth0.items : depth0),{"name":"each","hash":{},"fn":container.program(1, data, 0, blockParams, depths),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "                    <span class=\"visible-xs anchorHigh\">"
    + alias4(((helper = (helper = helpers.anchorHigh || (depth0 != null ? depth0.anchorHigh : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"anchorHigh","hash":{},"data":data}) : helper)))
    + "</span>\n                </div>\n                <span class=\"traitEndL\">"
    + alias4(((helper = (helper = helpers.anchorLow || (depth0 != null ? depth0.anchorLow : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"anchorLow","hash":{},"data":data}) : helper)))
    + "</span>\n                <div class=\"scoreSlider\">\n                    <input id=\"ex"
    + alias4(((helper = (helper = helpers.index || (depth0 != null ? depth0.index : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"index","hash":{},"data":data}) : helper)))
    + "\" class=\"scoreSliderInput\" data-slider-id=\"ex"
    + alias4(((helper = (helper = helpers.index || (depth0 != null ? depth0.index : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"index","hash":{},"data":data}) : helper)))
    + "Slider\" type=\"text\" data-slider-min=\"0\" data-slider-max=\""
    + alias4((helpers.minusOne || (depth0 && depth0.minusOne) || alias2).call(alias1,((stack1 = (depth0 != null ? depth0.items : depth0)) != null ? stack1.length : stack1),{"name":"minusOne","hash":{},"data":data}))
    + "\" data-slider-step=\"0.1\" data-slider-value=\""
    + alias4(((helper = (helper = helpers.initScore || (depth0 != null ? depth0.initScore : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"initScore","hash":{},"data":data}) : helper)))
    + "\"/>\n                </div>\n                <span class=\"traitEndR\">"
    + alias4(((helper = (helper = helpers.anchorHigh || (depth0 != null ? depth0.anchorHigh : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"anchorHigh","hash":{},"data":data}) : helper)))
    + "</span>\n            </div>\n\n        </div>\n    </div>\n</div>\n";
},"useData":true,"useDepths":true}));

Handlebars.registerPartial("scoringlastcard", this["JST"]["scoringlastcard"] = Handlebars.template({"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    return "<div class=\"lastCard section pushSection register layout-auto-height angleTopLeft\" data-bgimage=\"endcard\">\n\n</div>\n";
},"useData":true}));

Handlebars.registerPartial("sidebarbottommenu", this["JST"]["sidebarbottommenu"] = Handlebars.template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {});

  return "			"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.link : depth0),{"name":"if","hash":{},"fn":container.program(2, data, 0),"inverse":container.program(4, data, 0),"data":data})) != null ? stack1 : "")
    + "\n				<li><span>"
    + container.escapeExpression(((helper = (helper = helpers.text || (depth0 != null ? depth0.text : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(alias1,{"name":"text","hash":{},"data":data}) : helper)))
    + "</span></li>\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.items : depth0),{"name":"if","hash":{},"fn":container.program(6, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "			"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.link : depth0),{"name":"if","hash":{},"fn":container.program(8, data, 0),"inverse":container.program(10, data, 0),"data":data})) != null ? stack1 : "")
    + "\n";
},"2":function(container,depth0,helpers,partials,data) {
    var helper;

  return "<a href=\""
    + container.escapeExpression(((helper = (helper = helpers.link || (depth0 != null ? depth0.link : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"link","hash":{},"data":data}) : helper)))
    + "\">";
},"4":function(container,depth0,helpers,partials,data) {
    return "<div class=\"sidebar-inner-link\">";
},"6":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = container.invokePartial(partials.sidebarbottommenu,depth0,{"name":"sidebarbottommenu","data":data,"indent":"\t\t\t\t","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "");
},"8":function(container,depth0,helpers,partials,data) {
    return "</a>";
},"10":function(container,depth0,helpers,partials,data) {
    return "</div>";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "<div class=\"sidebar-inner-list inner-closed\">\n	<span class=\"sidebar-inner-back\">\n		<div class=\"sidebar-icon pe-7s-left-arrow\"></div>\n		<div class=\"sidebar-label\">Back</div>\n	</span>\n	<ul>\n"
    + ((stack1 = helpers.each.call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.items : depth0),{"name":"each","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "	</ul>\n</div>";
},"usePartial":true,"useData":true}));

Handlebars.registerPartial("sidebaritem", this["JST"]["sidebaritem"] = Handlebars.template({"1":function(container,depth0,helpers,partials,data) {
    return "a";
},"3":function(container,depth0,helpers,partials,data) {
    return "div";
},"5":function(container,depth0,helpers,partials,data) {
    return "sidebar-item-selected";
},"7":function(container,depth0,helpers,partials,data) {
    return "sidebar-item-nested";
},"9":function(container,depth0,helpers,partials,data) {
    var helper;

  return "href=\""
    + container.escapeExpression(((helper = (helper = helpers.link || (depth0 != null ? depth0.link : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"link","hash":{},"data":data}) : helper)))
    + "\"";
},"11":function(container,depth0,helpers,partials,data) {
    return "target=\"_blank\"";
},"13":function(container,depth0,helpers,partials,data) {
    return "sidebar-neverwrap";
},"15":function(container,depth0,helpers,partials,data) {
    var helper;

  return "			<div class=\"sidebar-item-data data-"
    + container.escapeExpression(((helper = (helper = helpers.dataName || (depth0 != null ? depth0.dataName : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"dataName","hash":{},"data":data}) : helper)))
    + "\"></div>\n";
},"17":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = container.invokePartial(partials.sidebartopmenu,depth0,{"name":"sidebartopmenu","data":data,"indent":"\t\t","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "");
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3="function", alias4=container.escapeExpression;

  return "<"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.link : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(3, data, 0),"data":data})) != null ? stack1 : "")
    + " class=\"sidebar-item "
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.isSelected : depth0),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + " "
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.items : depth0),{"name":"if","hash":{},"fn":container.program(7, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\" "
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.link : depth0),{"name":"if","hash":{},"fn":container.program(9, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + " "
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.newtab : depth0),{"name":"if","hash":{},"fn":container.program(11, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + ">\n	<div class=\"sidebar-container "
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.neverWrap : depth0),{"name":"if","hash":{},"fn":container.program(13, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\">\n		<div class=\"sidebar-icon "
    + alias4(((helper = (helper = helpers.iconClass || (depth0 != null ? depth0.iconClass : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"iconClass","hash":{},"data":data}) : helper)))
    + "\"></div>\n		<div class=\"sidebar-label "
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.dataName : depth0),{"name":"if","hash":{},"fn":container.program(13, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\">\n			"
    + alias4(((helper = (helper = helpers.text || (depth0 != null ? depth0.text : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"text","hash":{},"data":data}) : helper)))
    + "\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.dataName : depth0),{"name":"if","hash":{},"fn":container.program(15, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "		</div>\n	</div>\n\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.items : depth0),{"name":"if","hash":{},"fn":container.program(17, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\n</"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.link : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(3, data, 0),"data":data})) != null ? stack1 : "")
    + ">";
},"usePartial":true,"useData":true}));

Handlebars.registerPartial("sidebartopmenu", this["JST"]["sidebartopmenu"] = Handlebars.template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {});

  return "		"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.link : depth0),{"name":"if","hash":{},"fn":container.program(2, data, 0),"inverse":container.program(4, data, 0),"data":data})) != null ? stack1 : "")
    + "\n			<li><span>"
    + container.escapeExpression(((helper = (helper = helpers.text || (depth0 != null ? depth0.text : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(alias1,{"name":"text","hash":{},"data":data}) : helper)))
    + "</span></li>\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.items : depth0),{"name":"if","hash":{},"fn":container.program(6, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "		"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.link : depth0),{"name":"if","hash":{},"fn":container.program(8, data, 0),"inverse":container.program(10, data, 0),"data":data})) != null ? stack1 : "")
    + "\n";
},"2":function(container,depth0,helpers,partials,data) {
    var helper;

  return "<a href=\""
    + container.escapeExpression(((helper = (helper = helpers.link || (depth0 != null ? depth0.link : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"link","hash":{},"data":data}) : helper)))
    + "\">";
},"4":function(container,depth0,helpers,partials,data) {
    return "<div class=\"sidebar-inner-link\">";
},"6":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = container.invokePartial(partials.sidebarbottommenu,depth0,{"name":"sidebarbottommenu","data":data,"indent":"\t\t\t\t","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "");
},"8":function(container,depth0,helpers,partials,data) {
    return "</a>";
},"10":function(container,depth0,helpers,partials,data) {
    return "</div>";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "<ul class=\"sidebar-tier1 sidebar-list\">\n"
    + ((stack1 = helpers.each.call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.items : depth0),{"name":"each","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "</ul>";
},"usePartial":true,"useData":true}));

Handlebars.registerPartial("timelineinfo", this["JST"]["timelineinfo"] = Handlebars.template({"1":function(container,depth0,helpers,partials,data) {
    return "active";
},"3":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3="function";

  return "			<a class=\"btn alternate-btn\" href=\""
    + container.escapeExpression(((helper = (helper = helpers.link || (depth0 != null ? depth0.link : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"link","hash":{},"data":data}) : helper)))
    + "\">"
    + ((stack1 = ((helper = (helper = helpers.btntxt || (depth0 != null ? depth0.btntxt : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"btntxt","hash":{},"data":data}) : helper))) != null ? stack1 : "")
    + "</a>\n";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3="function", alias4=container.escapeExpression;

  return "<div class=\"item text-center "
    + ((stack1 = helpers["if"].call(alias1,(data && data.first),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\" data-period=\""
    + alias4(((helper = (helper = helpers.period || (depth0 != null ? depth0.period : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"period","hash":{},"data":data}) : helper)))
    + "\" data-period-length=\""
    + alias4(((helper = (helper = helpers.periodlength || (depth0 != null ? depth0.periodlength : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"periodlength","hash":{},"data":data}) : helper)))
    + "\">\n	<div class=\"inner-item\">\n		<h3>"
    + alias4(((helper = (helper = helpers.title || (depth0 != null ? depth0.title : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"title","hash":{},"data":data}) : helper)))
    + "</h3>\n		<h6>"
    + ((stack1 = ((helper = (helper = helpers.subtitle || (depth0 != null ? depth0.subtitle : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"subtitle","hash":{},"data":data}) : helper))) != null ? stack1 : "")
    + "</h6>\n		"
    + ((stack1 = ((helper = (helper = helpers.description || (depth0 != null ? depth0.description : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"description","hash":{},"data":data}) : helper))) != null ? stack1 : "")
    + "\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.link : depth0),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "      </div>\n</div>";
},"useData":true}));

this["JST"]["backgroundVideo"] = Handlebars.template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {});

  return " -->\n                <div class=\"item"
    + ((stack1 = helpers["if"].call(alias1,(data && data.first),{"name":"if","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\">\n                    <!-- "
    + ((stack1 = helpers.each.call(alias1,depth0,{"name":"each","hash":{},"fn":container.program(4, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + " -->\n                </div>\n            <!-- ";
},"2":function(container,depth0,helpers,partials,data) {
    return " active";
},"4":function(container,depth0,helpers,partials,data) {
    return " -->\n                        </div>\n                    <!-- ";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "<div>\n    <div id=\"promoVideoSlider\" class=\"carousel slide\" data-ride=\"carousel\" data-title=\"Hero Items\">\n      <div class=\"hero-slide\">\n        <h2>What Problem Are You</h2>\n        <h1>Ready To Solve?</h1>\n        <div class=\"intro-section-btn\"><span>Play Video</span><i>Icon</i></div>\n      </div>\n        <!-- Wrapper for slides -->\n        <div class=\"carousel-inner\" role=\"listbox\">\n            <!-- "
    + ((stack1 = helpers.each.call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.records : depth0),{"name":"each","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + " -->\n        </div>\n\n        <!-- Controls -->\n        <a class=\"left carousel-control\" href=\"#news-promobar\" role=\"button\" data-slide=\"prev\">\n            <span class=\"glyphicon glyphicon-chevron-left\" aria-hidden=\"true\"></span>\n            <span class=\"sr-only\">Previous</span>\n        </a>\n        <a class=\"right carousel-control\" href=\"#news-promobar\" role=\"button\" data-slide=\"next\">\n            <span class=\"glyphicon glyphicon-chevron-right\" aria-hidden=\"true\"></span>\n            <span class=\"sr-only\">Next</span>\n        </a>\n\n\n    </div>\n</div>\n";
},"useData":true});

this["JST"]["dashboardlinks"] = Handlebars.template({"1":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3="function", alias4=container.escapeExpression;

  return "		<a href=\""
    + alias4(((helper = (helper = helpers.link || (depth0 != null ? depth0.link : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"link","hash":{},"data":data}) : helper)))
    + "\" title=\""
    + alias4(((helper = (helper = helpers.text || (depth0 != null ? depth0.text : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"text","hash":{},"data":data}) : helper)))
    + "\">\n			<div class=\"linkText\">\n                <img class=\"mobile-nav-icons\" src=\""
    + alias4(((helper = (helper = helpers.icon || (depth0 != null ? depth0.icon : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"icon","hash":{},"data":data}) : helper)))
    + "\" width=\"30\" style=\"margin-right: 5px;\">\n				"
    + alias4(((helper = (helper = helpers.text || (depth0 != null ? depth0.text : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"text","hash":{},"data":data}) : helper)))
    + "\n			</div>\n		</a>\n";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "<div class=\"dashboardLinks\">\n"
    + ((stack1 = helpers.each.call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.links : depth0),{"name":"each","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "</div>";
},"useData":true});

this["JST"]["dashboardmenu"] = Handlebars.template({"1":function(container,depth0,helpers,partials,data) {
    return "				<a href=\"/logout.aspx\" class=\"logoutBtn retire-modal-trigger\">I want to Logout<i class=\"icon-arrow-right\"></i></a>\n";
},"3":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = (helpers.checkUser || (depth0 && depth0.checkUser) || helpers.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"registered",{"name":"checkUser","hash":{},"fn":container.program(4, data, 0),"inverse":container.program(6, data, 0),"data":data})) != null ? stack1 : "");
},"4":function(container,depth0,helpers,partials,data) {
    return "					<a href=\"#login\" class=\"loginBtn retire-modal-trigger\">I want to Login<i class=\"icon-arrow-right\"></i></a>\n";
},"6":function(container,depth0,helpers,partials,data) {
    return "					<a href=\"#register\" class=\"registerBtn retire-modal-trigger\">I want to Register<i class=\"icon-arrow-right\"></i></a>\n";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "<div id=\"mainMenu\" style=\"display:none;\">\n	<div id=\"centerMenuContent\"></div>\n    <div id=\"sideMenu\">\n        <div class=\"menuWrap\">\n            <div id=\"sidebar\"></div>\n        </div>\n        <div class=\"mobile-login\">\n	\n"
    + ((stack1 = (helpers.checkUser || (depth0 && depth0.checkUser) || helpers.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"loggedin",{"name":"checkUser","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(3, data, 0),"data":data})) != null ? stack1 : "")
    + "		</div>\n		<div class=\"closeWrap\">\n        </div>\n    </div>\n    <div id=\"actionMenu\">\n        <div class=\"validationMessage\"></div>\n        <div class=\"actionContent\"></div>\n    </div>\n</div>";
},"useData":true});

this["JST"]["faqsexpanderswipeless"] = Handlebars.template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3="function";

  return "	                    <div class=\"qa-card\" data-card-num=\""
    + container.escapeExpression(((helper = (helper = helpers.id || (depth0 != null ? depth0.id : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"id","hash":{},"data":data}) : helper)))
    + "\">\n	                        <div class=\"inner-col layout-vertical-center\">\n	                            <h3>"
    + ((stack1 = ((helper = (helper = helpers.question || (depth0 != null ? depth0.question : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"question","hash":{},"data":data}) : helper))) != null ? stack1 : "")
    + "</h3>\n	                            <div class=\"expand-bg\"></div>\n	                            <div class=\"hidden innerSwap\">\n	                                "
    + ((stack1 = ((helper = (helper = helpers.answer || (depth0 != null ? depth0.answer : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"answer","hash":{},"data":data}) : helper))) != null ? stack1 : "")
    + "\n	                            </div>\n	                        </div>\n	                    </div>\n";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "<div class=\"container\">\n	<div class=\"expanderNav skrollable\">\n		<span class=\"icon-icon-close close\"></span>\n		<span class=\"expander-prev nav-btn prev ng-icon-directionup\"></span>\n		<span class=\"expander-next nav-btn next ng-icon-directiondown\"></span>\n	</div>\n	<div class=\"row-wrap expanderGrid\">\n		<div class=\"faq-cards-container-outer\">\n			<div class=\"faq-cards-container\">\n				<div class=\"faq-cards-wrapper\">\n"
    + ((stack1 = helpers.each.call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.records : depth0),{"name":"each","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "				</div>\n			</div>\n			<div class=\"btn-wrap\">\n				<button class=\"js-faq-show-more faq-show-more\">Show More</button>\n			</div>\n		</div>\n		<div class=\"detailRow\">\n			<div class=\"expanderContent\"></div>\n		</div>\n	</div>\n</div>";
},"useData":true});

this["JST"]["finalists"] = Handlebars.template({"1":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = container.invokePartial(partials.finalistspost,depth0,{"name":"finalistspost","data":data,"indent":"                                ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "");
},"3":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = (helpers.ifComp || (depth0 && depth0.ifComp) || helpers.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),(data && data.index),"==",0,{"name":"ifComp","hash":{},"fn":container.program(4, data, 0),"inverse":container.program(6, data, 0),"data":data})) != null ? stack1 : "");
},"4":function(container,depth0,helpers,partials,data) {
    var helper;

  return "                                    <li data-target=\"#finalistsCarousel\" data-slide-to=\""
    + container.escapeExpression(((helper = (helper = helpers.index || (data && data.index)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"index","hash":{},"data":data}) : helper)))
    + "\" class=\"active\"></li>\n";
},"6":function(container,depth0,helpers,partials,data) {
    var helper;

  return "                                    <li data-target=\"#finalistsCarousel\" data-slide-to=\""
    + container.escapeExpression(((helper = (helper = helpers.index || (data && data.index)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"index","hash":{},"data":data}) : helper)))
    + "\"></li>\n";
},"8":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3="function", alias4=container.escapeExpression;

  return "                    <div class=\"finalistsBgLayer\" data-index=\""
    + alias4(((helper = (helper = helpers.index || (data && data.index)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"index","hash":{},"data":data}) : helper)))
    + "\" style=\"background-image:url('"
    + alias4(((helper = (helper = helpers.finalistBg || (depth0 != null ? depth0.finalistBg : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"finalistBg","hash":{},"data":data}) : helper)))
    + "');\"></div>\n";
},"10":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = container.invokePartial(partials.finalistsbios,depth0,{"name":"finalistsbios","data":data,"indent":"        ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "");
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {});

  return "<div class=\"container-fluid\" data-title=\"\">\n    <div class=\"row\">\n        <div class=\"col-xs-12 content\">\n            <section data-title=\"Learn\" class=\"text-center learnSection finalistsSection\">\n\n                <div class=\"row-wrap finalists\">\n\n                    <div id=\"finalistsCarousel\" class=\"carousel slide multi-item-carousel\">\n\n                        <div class=\"carousel-inner\" role=\"listbox\">\n"
    + ((stack1 = helpers.each.call(alias1,(depth0 != null ? depth0.steps : depth0),{"name":"each","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "                        </div>\n\n                        <ol class=\"carousel-indicators\">\n"
    + ((stack1 = helpers.each.call(alias1,(depth0 != null ? depth0.steps : depth0),{"name":"each","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "                        </ol>\n                        <a class=\"left carousel-control\" href=\"#finalistsCarousel\" role=\"button\" data-slide=\"prev\"></a>\n                        <a class=\"right carousel-control\" href=\"#finalistsCarousel\" role=\"button\" data-slide=\"next\"></a>\n\n                    </div>\n\n                </div>\n\n            </section>\n\n            <div class=\"finalistsBg\">\n"
    + ((stack1 = helpers.each.call(alias1,(depth0 != null ? depth0.steps : depth0),{"name":"each","hash":{},"fn":container.program(8, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "            </div>\n        </div>\n    </div>\n</div>\n\n<div class=\"finalist-overlay-grid\">\n"
    + ((stack1 = helpers.each.call(alias1,(depth0 != null ? depth0.steps : depth0),{"name":"each","hash":{},"fn":container.program(10, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "</div>";
},"usePartial":true,"useData":true});

this["JST"]["footer"] = Handlebars.template({"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    return "<footer>\n    <div class=\"text-center\">\n        <div class=\"legalLinks\">\n            <a href=\"#privacy-policy\">Privacy Policy</a>\n            <a href=\"#terms-conditions\">Terms &amp; Conditions</a>\n            <a href=\"mailto:questions@marineprotectionprize.com?subject=Marine Protection Prize question\">Contact</a>\n        </div>\n        <div class=\"copyright\">\n            <span class=\"centered\">\n              Video by National Geographic Pristine Seas; Images by Enric Sala.<br>\n              Challenge designed by <a href=\"http://www.commonpool.org/\" target=\"_blank\">Common Pool</a>; powered by <a href=\"https://www.rampit.com/\" target=\"_blank\">Rampit</a>. &copy; 2017 Rampit\n            </span>\n        </div>\n    </div>\n</footer>\n";
},"useData":true});

this["JST"]["forgotpassword"] = Handlebars.template({"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    return "<i class=\"register-login-forms-close ng-icon-close\"></i>\n<div class=\"formmessage layout-vertical-center\">\n    <div class=\"inner\">\n        <div class=\"modal-title\">\n            Forgot\n        </div>\n        <div class=\"contentModalWrap\">\n            <p>Please enter the email you registered with and then click send link.</p>\n        </div>\n    </div>\n</div>\n<div id=\"forgotPassword\" class=\"modal-container layout-vertical-center\">\n    <div class=\"inner\">\n        <div class=\"validationMessage\"></div>\n        <!--<div class=\"modal-title\">Forgot Password</div>-->\n        <div class=\"form message\" role=\"form\">\n            <div class=\"row modal-row-pad\">\n                <div class=\"col-xs-12\">\n                    <div class=\"form-group\">\n                        <input name=\"email\" type=\"email\" class=\"form-control firstLoad\">\n                        <span class=\"floating-label\">Email</span>\n                    </div>\n                </div>\n            </div>\n            <div class=\"row modal-row-pad\">\n                <div class=\"col-xs-12\">\n                    <button id=\"submitForgotBtn\" class=\"submitForgotBtn btn btn-primary\" type=\"submit\" disabled=\"disabled\">Reset</button>\n                </div>\n            </div>\n            <div class=\"register_message\" style=\"display: none\">\n                <div class=\"col-xs-12\"></div>\n            </div>\n        </div>\n    </div>\n    \n    <div class=\"toggle-screen\">\n        <a href=\"#login\" class=\"modal-switch btn btn-primary\">I want to Login</a>\n    </div>\n\n</div>\n\n";
},"useData":true});

this["JST"]["formmessage"] = Handlebars.template({"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3="function";

  return "<div class=\"messageWrap\">\n	<div class=\"modal-close icon-icon-close\"></div>\n	<div class=\"modal-back icon-icon-arrow-left-block\"></div>\n    <div class=\"modal-title\">"
    + container.escapeExpression(((helper = (helper = helpers.messageTitle || (depth0 != null ? depth0.messageTitle : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"messageTitle","hash":{},"data":data}) : helper)))
    + "</div>\n    <div class=\"contentModalWrap\">\n        <p>"
    + ((stack1 = ((helper = (helper = helpers.message || (depth0 != null ? depth0.message : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"message","hash":{},"data":data}) : helper))) != null ? stack1 : "")
    + "</p>\n    </div>\n</div>\n\n<div class=\"toggle-screen\">\n	<a href=\"#login\" class=\"modal-switch\">I want to <strong>Login</strong></a>\n</div>";
},"useData":true});

this["JST"]["judgeDetailsModal"] = Handlebars.template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3="function", alias4=container.escapeExpression;

  return "	            <div class=\"cardWrap border-box animated\" data-id=\""
    + alias4(((helper = (helper = helpers.id || (depth0 != null ? depth0.id : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"id","hash":{},"data":data}) : helper)))
    + "\" data-pagenav=\"true\">\n\n	            	<div class=\"inner-scrollable\">\n	            		<div class=\"modal-close-wrap\">\n	            			<i class=\"ng-icon-close icon-close close nav-btn\"></i>\n	            		</div>\n	            		<div class=\"detail\">\n	            			<div class=\"row\">\n	            				<div class=\"col-md-4 imageWrapLargeInner hidden-xs\">\n	            					<div class=\"judges-detail-image\">\n			            				<div class=\"inner-image\" style=\"background-image: url("
    + alias4(((helper = (helper = helpers.image || (depth0 != null ? depth0.image : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"image","hash":{},"data":data}) : helper)))
    + ")\">\n\n			            				</div>\n			            			</div>\n	            				</div>\n	            				<div class=\"col-md-7\">\n	            					<div class=\"tplContentContainer\">\n	            						<div class=\"content-container\">\n				                            <div class=\"judgeTop\">\n				                              	<div class=\"inner-image visible-xs\" style=\"background-image: url("
    + alias4(((helper = (helper = helpers.image || (depth0 != null ? depth0.image : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"image","hash":{},"data":data}) : helper)))
    + ")\"></div>\n													<div class=\"judgeHeadline\">\n														<div class=\"judgeHeadlineInner\">\n															<span class=\"judgeGroupSpan\">"
    + alias4(((helper = (helper = helpers.type || (depth0 != null ? depth0.type : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"type","hash":{},"data":data}) : helper)))
    + "</span>\n															<h4 class=\"name\">"
    + alias4(((helper = (helper = helpers.h3 || (depth0 != null ? depth0.h3 : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"h3","hash":{},"data":data}) : helper)))
    + "</h4>\n															<h6 class=\"title\">"
    + alias4(((helper = (helper = helpers.h4 || (depth0 != null ? depth0.h4 : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"h4","hash":{},"data":data}) : helper)))
    + "</h6>\n														</div>\n													</div>\n				                            	</div>\n				                            <div class=\"content\">\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.quote : depth0),{"name":"if","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "				                            	"
    + ((stack1 = ((helper = (helper = helpers.content || (depth0 != null ? depth0.content : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"content","hash":{},"data":data}) : helper))) != null ? stack1 : "")
    + "\n				                            </div>\n		            					</div>\n	            					</div>\n	            				</div>\n	            			</div>\n	            		</div>\n	            	</div>\n\n	            </div>\n	            <!-- end cardWrap -->\n";
},"2":function(container,depth0,helpers,partials,data) {
    var helper;

  return "				                            	<span class=\"judge-details-quote\"><q>"
    + container.escapeExpression(((helper = (helper = helpers.quote || (depth0 != null ? depth0.quote : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"quote","hash":{},"data":data}) : helper)))
    + "</q></span>\n";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "<div class=\"tplWrapper modal-judges\">\n    <div class=\"tplNavWrapper\">\n        <div class=\"sideNavWrap\" data-scrollparent=\".tplWrapper\"></div>\n    </div>\n    <div class=\"cardWrapper\">\n    	<div class=\"container\">\n"
    + ((stack1 = helpers.each.call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.records : depth0),{"name":"each","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "    	</div>\n    </div>\n</div>\n";
},"useData":true});

this["JST"]["judgespage"] = Handlebars.template({"1":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = container.invokePartial(partials.judgegroup,depth0,{"name":"judgegroup","data":data,"indent":"         ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "");
},"3":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "    <div class=\"judgeTypeBar\" style=\"opacity:0;\" data-0=\"opacity:0;bottom:0px;\" data-600=\"opacity:0;\" data-700=\"opacity:1;\" data-end=\"bottom:60px;\" data-60-end=\"bottom:0px;\">\n"
    + ((stack1 = helpers.each.call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = (depth0 != null ? depth0.data : depth0)) != null ? stack1.judgeGroups : stack1),{"name":"each","hash":{},"fn":container.program(4, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "    </div>\n";
},"4":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3="function", alias4=container.escapeExpression;

  return "            <span class=\""
    + ((stack1 = (helpers.ifCond || (depth0 && depth0.ifCond) || alias2).call(alias1,(data && data.index),0,{"name":"ifCond","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\" data-judgegroup=\""
    + alias4(((helper = (helper = helpers.name || (depth0 != null ? depth0.name : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"name","hash":{},"data":data}) : helper)))
    + "\">"
    + alias4(((helper = (helper = helpers.name || (depth0 != null ? depth0.name : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"name","hash":{},"data":data}) : helper)))
    + "</span>\n";
},"5":function(container,depth0,helpers,partials,data) {
    return "enabled";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {});

  return "<div id=\"judges\" class=\"container\">\n"
    + ((stack1 = helpers.each.call(alias1,((stack1 = (depth0 != null ? depth0.data : depth0)) != null ? stack1.judgeGroups : stack1),{"name":"each","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "</div>\n\n<div id=\"judgesDetail\">\n</div>\n\n"
    + ((stack1 = helpers["if"].call(alias1,((stack1 = (depth0 != null ? depth0.config : depth0)) != null ? stack1.showTypeBar : stack1),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "");
},"usePartial":true,"useData":true});

this["JST"]["judgespreview"] = Handlebars.template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3="function", alias4=container.escapeExpression;

  return "                <div class=\"item "
    + ((stack1 = (helpers.ifCond || (depth0 && depth0.ifCond) || alias2).call(alias1,(data && data.index),0,{"name":"ifCond","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\" data-judgeid=\""
    + alias4(((helper = (helper = helpers.id || (depth0 != null ? depth0.id : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"id","hash":{},"data":data}) : helper)))
    + "\">\n                    <div class=\"container\">\n                        <div class=\"row\">\n                            <div class=\"col-sm-4 judge-image-wrap hidden-xs\">\n                                <img src=\""
    + alias4(((helper = (helper = helpers.image || (depth0 != null ? depth0.image : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"image","hash":{},"data":data}) : helper)))
    + "\" alt=\""
    + alias4(((helper = (helper = helpers.firstName || (depth0 != null ? depth0.firstName : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"firstName","hash":{},"data":data}) : helper)))
    + " "
    + alias4(((helper = (helper = helpers.lastName || (depth0 != null ? depth0.lastName : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"lastName","hash":{},"data":data}) : helper)))
    + "\" class=\"img-responsive\" />\n                            </div>\n                            <div class=\"col-sm-8\">\n                                <h5 class=\"section-title hidden-xs\">Judges' Spotlight</h5>\n                                <div class=\"judgePreviewQuote-Container layout-vertical-center\">\n									<div class=\"judgePreviewQuote\">\n										<p class=\"judge-quote\"><q>"
    + alias4(((helper = (helper = helpers.quote || (depth0 != null ? depth0.quote : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"quote","hash":{},"data":data}) : helper)))
    + "</q></p>\n										<img src=\""
    + alias4(((helper = (helper = helpers.image || (depth0 != null ? depth0.image : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"image","hash":{},"data":data}) : helper)))
    + "\" alt=\""
    + alias4(((helper = (helper = helpers.firstName || (depth0 != null ? depth0.firstName : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"firstName","hash":{},"data":data}) : helper)))
    + " "
    + alias4(((helper = (helper = helpers.lastName || (depth0 != null ? depth0.lastName : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"lastName","hash":{},"data":data}) : helper)))
    + "\" class=\"img-responsive\" />\n										<span class=\"judge-credentials\">"
    + alias4(((helper = (helper = helpers.title || (depth0 != null ? depth0.title : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"title","hash":{},"data":data}) : helper)))
    + " "
    + alias4(((helper = (helper = helpers.firstName || (depth0 != null ? depth0.firstName : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"firstName","hash":{},"data":data}) : helper)))
    + " "
    + alias4(((helper = (helper = helpers.lastName || (depth0 != null ? depth0.lastName : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"lastName","hash":{},"data":data}) : helper)))
    + "</span>\n									</div>\n                                </div>\n                                <div class=\"btn-wrap\">\n                                    <a class=\"btn uppercase\" href=\"#judges/"
    + alias4(((helper = (helper = helpers.id || (depth0 != null ? depth0.id : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"id","hash":{},"data":data}) : helper)))
    + "\">Meet "
    + alias4(((helper = (helper = helpers.firstName || (depth0 != null ? depth0.firstName : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"firstName","hash":{},"data":data}) : helper)))
    + "</a>\n                                </div>\n                            </div>\n                        </div>\n                    </div>\n                </div>\n";
},"2":function(container,depth0,helpers,partials,data) {
    return "active";
},"4":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3="function", alias4=container.escapeExpression;

  return "                <div class=\"judgebox thumb "
    + ((stack1 = (helpers.ifCond || (depth0 && depth0.ifCond) || alias2).call(alias1,(data && data.index),0,{"name":"ifCond","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\" data-judgeid=\""
    + alias4(((helper = (helper = helpers.id || (depth0 != null ? depth0.id : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"id","hash":{},"data":data}) : helper)))
    + "\">\n                    <div class=\"judgebox-inner-thumb\" style=\"background-image:url("
    + alias4(((helper = (helper = helpers.thumbnail || (depth0 != null ? depth0.thumbnail : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"thumbnail","hash":{},"data":data}) : helper)))
    + ");\"></div>\n                </div>\n";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {});

  return "<h5 class=\"section-title visible-xs\">Judges' Spotlight</h5>\n<div class=\"activeContainer container-fluid\">\n    <div class=\"carousel slide\">\n        <div class=\"container container-special-visual\"></div>\n        <div class=\"carousel-inner\">\n"
    + ((stack1 = helpers.each.call(alias1,depth0,{"name":"each","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "        </div>\n        <a class=\"left nav-btn carousel-control ng-icon-directionleft\" data-target=\".judgesPreviewSection .carousel\" role=\"button\" data-slide=\"prev\">\n            <span class=\"sr-only\">Previous</span>\n        </a>\n        <a class=\"right nav-btn carousel-control ng-icon-directionright\" data-target=\".judgesPreviewSection .carousel\" role=\"button\" data-slide=\"next\">\n            <span class=\"sr-only\">Next</span>\n        </a>\n    </div>\n</div>\n<div class=\"container\">\n    <div class=\"thumbSlider\">\n        <div>\n"
    + ((stack1 = helpers.each.call(alias1,depth0,{"name":"each","hash":{},"fn":container.program(4, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "        </div>\n    </div>\n</div>\n";
},"useData":true});

this["JST"]["lastcard"] = Handlebars.template({"1":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "\n"
    + ((stack1 = (helpers.ifComp || (depth0 && depth0.ifComp) || helpers.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = (depth0 != null ? depth0.config : depth0)) != null ? stack1.rampitSubTimeLeft : stack1),">",0,{"name":"ifComp","hash":{},"fn":container.program(2, data, 0),"inverse":container.program(4, data, 0),"data":data})) != null ? stack1 : "")
    + "\n";
},"2":function(container,depth0,helpers,partials,data) {
    return "						<h3>Only <span class=\"daysLeft\">60</span> <span class=\"dayLeftCountWord\">days</span> to submit</h3>\n						<a href=\"pw\" class=\"btn solid btn-primary retire-modal-trigger\" type=\"submit\">Return to Dashboard</a>\n";
},"4":function(container,depth0,helpers,partials,data) {
    return "						<h3>Let’s work together to protect our oceans and sustain our fisheries.</h3>\n						<a href=\"#about\" class=\"btn solid btn-primary\" type=\"submit\">Learn more</a>\n";
},"6":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = (helpers.checkUser || (depth0 && depth0.checkUser) || helpers.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"registered",{"name":"checkUser","hash":{},"fn":container.program(7, data, 0),"inverse":container.program(12, data, 0),"data":data})) != null ? stack1 : "");
},"7":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "\n"
    + ((stack1 = (helpers.ifComp || (depth0 && depth0.ifComp) || helpers.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = (depth0 != null ? depth0.config : depth0)) != null ? stack1.rampitSubTimeLeft : stack1),">",0,{"name":"ifComp","hash":{},"fn":container.program(8, data, 0),"inverse":container.program(10, data, 0),"data":data})) != null ? stack1 : "")
    + "\n";
},"8":function(container,depth0,helpers,partials,data) {
    return "							<h3>Only <span class=\"daysLeft\">60</span> <span class=\"dayLeftCountWord\">days</span> to submit</h3>\n							<a href=\"#login\" class=\"btn solid btn-primary retire-modal-trigger\" type=\"submit\">Login</a>\n";
},"10":function(container,depth0,helpers,partials,data) {
    return "							<h3>Let’s work together to protect our oceans and sustain our fisheries.</h3>\n							<a href=\"#login\" class=\"btn solid btn-primary retire-modal-trigger\" type=\"submit\">Login</a>\n";
},"12":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = (helpers.ifComp || (depth0 && depth0.ifComp) || helpers.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = (depth0 != null ? depth0.config : depth0)) != null ? stack1.rampitRegTimeLeft : stack1),">",0,{"name":"ifComp","hash":{},"fn":container.program(13, data, 0),"inverse":container.program(15, data, 0),"data":data})) != null ? stack1 : "");
},"13":function(container,depth0,helpers,partials,data) {
    return "							<h3>Only <span class=\"daysCount\">64</span>&nbsp;<span class=\"dayCountWord\">Days</span> left to register</h3>\n							<a href=\"#register\" class=\"btn solid btn-primary retire-modal-trigger\" type=\"submit\">Register today</a>							\n";
},"15":function(container,depth0,helpers,partials,data) {
    return "							<h3>Let’s work together to protect our oceans and sustain our fisheries.</h3>\n							<a href=\"#about\" class=\"btn solid btn-primary\" type=\"submit\">Learn more</a>							\n";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "<div class=\"container\">\n	<div class=\"introSection layout-vertical-center\">\n		<div class=\"row\">\n			<div class=\"col-md-12 content register\">\n				<div class=\"text-center retired-load_all_last_card\">\n					<h2>Help us detect illegal fishing</h2>\n				</div>\n\n"
    + ((stack1 = (helpers.checkUser || (depth0 && depth0.checkUser) || helpers.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"loggedin",{"name":"checkUser","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(6, data, 0),"data":data})) != null ? stack1 : "")
    + "\n				\n			</div>\n		</div>\n	</div>\n</div>";
},"useData":true});

this["JST"]["login"] = Handlebars.template({"1":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "value=\""
    + container.escapeExpression(container.lambda(((stack1 = ((stack1 = (depth0 != null ? depth0.config : depth0)) != null ? stack1.regConfig : stack1)) != null ? stack1.email : stack1), depth0))
    + "\"";
},"3":function(container,depth0,helpers,partials,data) {
    return "						<a href=\"#register\">Don't have an account?</a>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;\n";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {});

  return "<i class=\"register-login-forms-close ng-icon-close\"></i>\n<div class=\"formmessage layout-vertical-center\">\n	<div class=\"inner\">\n		<div class=\"modal-title\">\n			Login\n		</div>\n		<div class=\"contentModalWrap\">\n			<p>To complete or view your application, please login.</p>\n		</div>\n	</div>\n</div>\n<div id=\"login\" class=\"modal-container layout-vertical-center\">\n	<div class=\"inner\">\n		<div class=\"validationMessage\"></div>\n		<!--<div class=\"modal-title\">Login</div>-->\n		<div class=\"form login\" role=\"form\">\n			<div class=\"row modal-row-pad\">\n				<div class=\"col-xs-12\">\n					<div class=\"form-group\">\n						<input name=\"email\" type=\"email\" id=\"\" placeholder=\"\" class=\"form-control firstLoad\" "
    + ((stack1 = helpers["if"].call(alias1,((stack1 = ((stack1 = (depth0 != null ? depth0.config : depth0)) != null ? stack1.regConfig : stack1)) != null ? stack1.email : stack1),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + ">\n						<span class=\"floating-label\">Email</span>\n					</div>\n				</div>\n			</div>\n			<div class=\"row modal-row-pad\">\n				<div class=\"col-xs-12\">\n					<div class=\"form-group\">\n						<input name=\"password\" type=\"password\" id=\"\" placeholder=\"\" class=\"form-control\">\n						<span class=\"floating-label\">Password</span>\n					</div>\n				</div>\n			</div>\n		\n			<div class=\"row modal-row-pad social-login-row\">\n				<span class=\"subtext above-social-login\">\n"
    + ((stack1 = (helpers.ifComp || (depth0 && depth0.ifComp) || helpers.helperMissing).call(alias1,((stack1 = (depth0 != null ? depth0.config : depth0)) != null ? stack1.rampitRegTimeLeft : stack1),">",0,{"name":"ifComp","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "					<a href=\"#forgot\">Forgot Password?</a>\n				</span>\n			\n			\n			\n			</div>\n		\n			<div class=\"row modal-row-pad below-social-login-btn\">\n				<div class=\"col-xs-12\">\n					<button id=\"signinButton\" class=\"signinButton btn btn-primary\" type=\"submit\" disabled=\"disabled\">Login</button>\n				</div>\n			</div>\n		</div>\n	</div>\n	\n	<div class=\"socialLogin\">\n		<div class=\"row modal-row-pad\">\n			<div class=\"socialLinks\">\n				<a id=\"facebookButton\" href=\"#\">\n					<span class=\"bg\"></span>\n					<span class=\"txt\">Login with Facebook</span>\n				</a>\n				<a id=\"googleButton\" href=\"#\">\n					<span class=\"bg\"></span>\n					<span class=\"txt\">Login with Google</span>\n				</a>\n			\n			</div>\n		</div>\n	</div>\n</div>\n\n";
},"useData":true});

this["JST"]["navbar"] = Handlebars.template({"1":function(container,depth0,helpers,partials,data) {
    return "        		<span class=\"daysToRegister\">\n					<a href=\"/pw\" class=\"btn solid navreg retire-modal-trigger\">Return to Dashboard</a>\n					<a class=\"btn solid modal-close\">\n                        <span>Back To <strong class=\"activePageTitle\">Home</strong></span>\n                    </a>\n				</span>\n\n";
},"3":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = (helpers.checkUser || (depth0 && depth0.checkUser) || helpers.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"registered",{"name":"checkUser","hash":{},"fn":container.program(4, data, 0),"inverse":container.program(6, data, 0),"data":data})) != null ? stack1 : "");
},"4":function(container,depth0,helpers,partials,data) {
    return "				<span class=\"daysToRegister\">\n					<a href=\"#login\" class=\"btn solid navreg retire-modal-trigger\">Login</a>\n					<a class=\"btn solid modal-close\">\n					    <span>Back To <strong class=\"activePageTitle\">Home</strong></span>\n                    </a>\n				</span>\n";
},"6":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = (helpers.ifComp || (depth0 && depth0.ifComp) || helpers.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = (depth0 != null ? depth0.timeConfig : depth0)) != null ? stack1.rampitRegTimeLeft : stack1),">",0,{"name":"ifComp","hash":{},"fn":container.program(7, data, 0),"inverse":container.program(9, data, 0),"data":data})) != null ? stack1 : "");
},"7":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression;

  return "                        <span class=\"daysToRegister\">\n                            <a href=\"#register\" class=\"btn solid navreg retire-modal-trigger\">\n                                <span class=\"dayCount\">"
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.timeConfig : depth0)) != null ? stack1.rampitRegTimeLeft : stack1), depth0))
    + "</span>&nbsp;<span><span class=\"dayCountWord\">"
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.timeConfig : depth0)) != null ? stack1.rampitRegTimeFrame : stack1), depth0))
    + "</span> to <strong>Register</strong></span>\n                            </a>\n                            <a class=\"btn solid modal-close\">\n                                <span>Back To <strong class=\"activePageTitle\">Home</strong></span>\n                            </a>\n                        </span>\n";
},"9":function(container,depth0,helpers,partials,data) {
    return "                        <span class=\"daysToRegister\">\n                            <a href=\"#login\" class=\"btn solid navreg retire-modal-trigger\">Login</a>\n                            <a class=\"btn solid modal-close\">\n                                <span>Back To <strong class=\"activePageTitle\">Home</strong></span>\n                            </a>\n                        </span>\n";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "<nav id=\"mainNavbar\" class=\"navbar navbar-default\" role=\"navigation\">\n\n        <div class=\"navbar-header layout-vertical-center\">\n            <a class=\"navbar-brand anim\" href=\"/#home\"></a>\n        </div>\n\n        <div class=\"dashboard-menu-container\">\n        	<div class=\"dashboardToggle dropdown\">\n				<a href=\"\">\n					<div class=\"icon hamburger-to-close\">\n						<div class=\"hamburger-middle\"></div>\n					</div>\n					<span class=\"dashboard-menu-text\">Menu</span>\n				</a>\n			</div>\n\n\n"
    + ((stack1 = (helpers.checkUser || (depth0 && depth0.checkUser) || helpers.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"loggedin",{"name":"checkUser","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(3, data, 0),"data":data})) != null ? stack1 : "")
    + "		</div>\n</nav>\n";
},"useData":true});

this["JST"]["newsDetailsModal"] = Handlebars.template({"1":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "                <a href=\"#news/"
    + container.escapeExpression(container.lambda(((stack1 = ((stack1 = (depth0 != null ? depth0.item : depth0)) != null ? stack1.prevItem : stack1)) != null ? stack1.id : stack1), depth0))
    + "\" class=\"prev\">\n                    <span class=\"nav-btn ng-icon-directionleft\"></span>\n                </a>\n";
},"3":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "                <a href=\"#news/"
    + container.escapeExpression(container.lambda(((stack1 = ((stack1 = (depth0 != null ? depth0.item : depth0)) != null ? stack1.nextItem : stack1)) != null ? stack1.id : stack1), depth0))
    + "\" class=\"next\">\n                    <span class=\"nav-btn ng-icon-directionright\"></span>\n                </a>\n";
},"5":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = (helpers.ifComp || (depth0 && depth0.ifComp) || helpers.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = ((stack1 = (depth0 != null ? depth0.item : depth0)) != null ? stack1.prevItem : stack1)) != null ? stack1.id : stack1),"!=",((stack1 = ((stack1 = (depth0 != null ? depth0.item : depth0)) != null ? stack1.nextItem : stack1)) != null ? stack1.id : stack1),{"name":"ifComp","hash":{},"fn":container.program(6, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "");
},"6":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression;

  return "\n                                <a href=\"#news/"
    + alias2(alias1(((stack1 = ((stack1 = (depth0 != null ? depth0.item : depth0)) != null ? stack1.prevItem : stack1)) != null ? stack1.id : stack1), depth0))
    + "\" class=\"additional-post prev-post\">\n                                    <span class=\"mobile-handle hidden-lg hidden-md hidden-sm\">\n                                        <span class=\"icon ng-icon-directionleft\"></span>\n                                        <span class=\"txt\">Previous</span>\n                                    </span>\n                                    <div class=\"additional-post-content-wrapper\">\n                                        <div class=\"additional-post-image\" style=\"background-image: url("
    + alias2(alias1(((stack1 = ((stack1 = (depth0 != null ? depth0.item : depth0)) != null ? stack1.prevItem : stack1)) != null ? stack1.image : stack1), depth0))
    + ")\"></div>\n                                        <p class=\"additional-post-date\">"
    + alias2(alias1(((stack1 = ((stack1 = (depth0 != null ? depth0.item : depth0)) != null ? stack1.prevItem : stack1)) != null ? stack1.date : stack1), depth0))
    + "</p>\n                                        <h4 class=\"additional-post-title\">"
    + alias2(alias1(((stack1 = ((stack1 = (depth0 != null ? depth0.item : depth0)) != null ? stack1.prevItem : stack1)) != null ? stack1.title : stack1), depth0))
    + "</h4>\n                                        <button class=\"js-newsDetailsPrevBtn btn btn-primary\">PREVIOUS POST</button>\n                                    </div>\n                                </a>\n\n                                <a href=\"#news/"
    + alias2(alias1(((stack1 = ((stack1 = (depth0 != null ? depth0.item : depth0)) != null ? stack1.nextItem : stack1)) != null ? stack1.id : stack1), depth0))
    + "\" class=\"additional-post next-post\">\n                                    <span class=\"mobile-handle hidden-lg hidden-md hidden-sm\">\n                                        <span class=\"txt\">Next</span>\n                                        <span class=\"icon ng-icon-directionright\"></span>\n                                    </span>\n                                    <div class=\"additional-post-content-wrapper\">\n                                        <div class=\"additional-post-image\" style=\"background-image: url("
    + alias2(alias1(((stack1 = ((stack1 = (depth0 != null ? depth0.item : depth0)) != null ? stack1.nextItem : stack1)) != null ? stack1.image : stack1), depth0))
    + ")\"></div>\n                                        <p class=\"additional-post-date\">"
    + alias2(alias1(((stack1 = ((stack1 = (depth0 != null ? depth0.item : depth0)) != null ? stack1.nextItem : stack1)) != null ? stack1.date : stack1), depth0))
    + "</p>\n                                        <h4 class=\"additional-post-title\">"
    + alias2(alias1(((stack1 = ((stack1 = (depth0 != null ? depth0.item : depth0)) != null ? stack1.nextItem : stack1)) != null ? stack1.title : stack1), depth0))
    + "</h4>\n                                        <button class=\"btn btn-primary\">NEXT POST</button>\n                                    </div>\n                                </a>\n";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.lambda, alias3=container.escapeExpression;

  return "<div class=\"modal-news\">\n    <div class=\"modal-nav-wrap\">\n        <div class=\"modal-nav-controls-wrap\">\n"
    + ((stack1 = helpers["if"].call(alias1,((stack1 = (depth0 != null ? depth0.item : depth0)) != null ? stack1.prevItem : stack1),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + ((stack1 = helpers["if"].call(alias1,((stack1 = (depth0 != null ? depth0.item : depth0)) != null ? stack1.prevItem : stack1),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "        </div>\n    </div>\n    <div class=\"cardWrapper\">\n        <div class=\"container\">\n\n            <div class=\"cardWrap\">\n\n                <div class=\"inner-scrollable\">\n                    <div class=\"modal-close-wrap\">\n                        <span class=\"nav-btn modal-close\">\n                            <span class=\"ng-icon-close\"></span>\n                        </span>\n                    </div>\n                    <div class=\"detail\">\n                        <div class=\"row\">\n                            <div class=\"col-md-2\">\n                                <div class=\"news-detail-share-container layout-vertical-center news-sidesocial-container\">\n                                    <div class=\"inner-share\">\n                                        <span class=\"share\">Share</span>\n                                        <br/>\n                                        <span class=\"ng-icon-facebook shareSocial\" data-action=\"facebook\"\n                                           data-title=\""
    + alias3(alias2(((stack1 = (depth0 != null ? depth0.item : depth0)) != null ? stack1.title : stack1), depth0))
    + "\"></span>\n                                        <br/>\n                                        <span class=\"ng-icon-twitter shareSocial\" data-action=\"twitter\"\n                                           data-title=\""
    + alias3(alias2(((stack1 = (depth0 != null ? depth0.item : depth0)) != null ? stack1.title : stack1), depth0))
    + "\"></span>\n                                    </div>\n                                </div>\n                            </div>\n                            <div class=\"col-md-8\">\n                                <div class=\"modal-content-container\">\n                                    <div class=\"content-container\">\n                                        <span class=\"news-type-tag\">"
    + alias3(alias2(((stack1 = (depth0 != null ? depth0.item : depth0)) != null ? stack1.category : stack1), depth0))
    + "</span>\n                                        <span class=\"news-item-date\">"
    + alias3(alias2(((stack1 = (depth0 != null ? depth0.item : depth0)) != null ? stack1.date : stack1), depth0))
    + "</span>\n                                        <h4 class=\"news-item-title\">"
    + alias3(alias2(((stack1 = (depth0 != null ? depth0.item : depth0)) != null ? stack1.title : stack1), depth0))
    + "</h4>\n                                        <div class=\"imageWrapLargeInner\">\n                                            <div class=\"news-detail-image\" style=\"background-image: url("
    + alias3(alias2(((stack1 = (depth0 != null ? depth0.item : depth0)) != null ? stack1.image : stack1), depth0))
    + ")\"></div>\n                                        </div>\n                                        <div class=\"content\">\n                                            <p>"
    + ((stack1 = alias2(((stack1 = (depth0 != null ? depth0.item : depth0)) != null ? stack1.content : stack1), depth0)) != null ? stack1 : "")
    + "</p>\n                                        </div>\n                                    </div>\n                                </div>\n                            </div>\n                        </div>\n                    </div>\n                    <div class=\"additional-posts-container\">\n"
    + ((stack1 = helpers["if"].call(alias1,((stack1 = (depth0 != null ? depth0.item : depth0)) != null ? stack1.prevItem : stack1),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "                    </div>\n                </div>\n\n            </div>\n\n        </div>\n    </div>\n</div>\n";
},"useData":true});

this["JST"]["newspage"] = Handlebars.template({"1":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = container.invokePartial(partials.newspromocarousel,depth0,{"name":"newspromocarousel","data":data,"indent":"\t","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "");
},"3":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = (depth0 != null ? depth0.promoNewsItems : depth0)) != null ? stack1["0"] : stack1),{"name":"if","hash":{},"fn":container.program(4, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "");
},"4":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "			<div class=\"row\">\n				<div class=\"col-md-12\">\n					<div class=\"row newsPromoGrid\">\n"
    + ((stack1 = container.invokePartial(partials.newspromobox,((stack1 = (depth0 != null ? depth0.promoNewsItems : depth0)) != null ? stack1["0"] : stack1),{"name":"newspromobox","data":data,"indent":"\t\t\t\t\t\t","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "					</div>\n				</div>\n			</div>\n";
},"6":function(container,depth0,helpers,partials,data) {
    var helper;

  return "						<div class=\"tab\">"
    + container.escapeExpression(((helper = (helper = helpers.name || (depth0 != null ? depth0.name : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"name","hash":{},"data":data}) : helper)))
    + "</div>\n";
},"8":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = (helpers.ifComp || (depth0 && depth0.ifComp) || helpers.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),(data && data.index),">",0,{"name":"ifComp","hash":{},"fn":container.program(9, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "");
},"9":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = container.invokePartial(partials.newslistingentry,depth0,{"name":"newslistingentry","data":data,"indent":"\t\t\t\t\t\t","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "");
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {});

  return "<div class=\"container container-special-visual\"></div>\n<a class=\"left carousel-control nav-btn ng-icon-directionleft\" href=\"#newsPromoGridCarousel\" data-slide=\"prev\"></a>\n<a class=\"right carousel-control nav-btn ng-icon-directionright\" href=\"#newsPromoGridCarousel\" data-slide=\"next\"></a>\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.usecarousel : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(3, data, 0),"data":data})) != null ? stack1 : "")
    + "<div class=\"container news-listing-container\">\n	<div class=\"row\">\n		<div class=\"col-md-12\">\n			<div class=\"row newsListing\">\n				<div class=\"newsListingTabs\">\n"
    + ((stack1 = helpers.each.call(alias1,(depth0 != null ? depth0.categories : depth0),{"name":"each","hash":{},"fn":container.program(6, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "				</div>\n"
    + ((stack1 = helpers.each.call(alias1,(depth0 != null ? depth0.regularNewsItems : depth0),{"name":"each","hash":{},"fn":container.program(8, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "			</div>\n		</div>\n	</div>\n\n\n	<div class=\"newsDetailContainer\"></div>\n</div>\n";
},"usePartial":true,"useData":true});

this["JST"]["newspost"] = Handlebars.template({"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3="function", alias4=container.escapeExpression;

  return "<div class=\"newsinfoWrap border-box\" data-news=\""
    + alias4(((helper = (helper = helpers.id || (depth0 != null ? depth0.id : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"id","hash":{},"data":data}) : helper)))
    + "\" data-pagenav=\"true\">\n    <div class=\"row news-detail\">\n        <div class=\"col-md-5\">\n            <div class=\"newsImageWrapLarge\" style=\"background-image:url("
    + alias4(((helper = (helper = helpers.thumbnail || (depth0 != null ? depth0.thumbnail : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"thumbnail","hash":{},"data":data}) : helper)))
    + ");\"></div>\n        </div>\n        <div class=\"col-md-6 inner-scrollable\">\n            <span>"
    + alias4(((helper = (helper = helpers.type || (depth0 != null ? depth0.type : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"type","hash":{},"data":data}) : helper)))
    + "</span>\n            <h3 class=\"name\">"
    + alias4(((helper = (helper = helpers.name || (depth0 != null ? depth0.name : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"name","hash":{},"data":data}) : helper)))
    + "</h3>\n            <h4>"
    + alias4(((helper = (helper = helpers.shortDescription || (depth0 != null ? depth0.shortDescription : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"shortDescription","hash":{},"data":data}) : helper)))
    + "</h4>\n            <p>"
    + ((stack1 = ((helper = (helper = helpers.longDescription || (depth0 != null ? depth0.longDescription : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"longDescription","hash":{},"data":data}) : helper))) != null ? stack1 : "")
    + "</p>\n        </div>\n    </div>\n</div>";
},"useData":true});

this["JST"]["processslidecard"] = Handlebars.template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3="function", alias4=container.escapeExpression;

  return "                <div class=\"swiper-slide\">\n                    <a href=\""
    + ((stack1 = ((helper = (helper = helpers.btnLink || (depth0 != null ? depth0.btnLink : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"btnLink","hash":{},"data":data}) : helper))) != null ? stack1 : "")
    + "\" class=\"swiper-slide-inner clearfix\">\n                        <div class=\"img-placeholder\">\n                            <div class=\"img-placeholder-inner\" style=\"background-image: url("
    + alias4(((helper = (helper = helpers.image || (depth0 != null ? depth0.image : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"image","hash":{},"data":data}) : helper)))
    + ");\"></div>\n                        </div>\n                        <div class=\"txt-wrap\">\n                            <h3>"
    + alias4(((helper = (helper = helpers.title || (depth0 != null ? depth0.title : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"title","hash":{},"data":data}) : helper)))
    + "</h3>\n                            <p>"
    + ((stack1 = ((helper = (helper = helpers.content || (depth0 != null ? depth0.content : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"content","hash":{},"data":data}) : helper))) != null ? stack1 : "")
    + "</p>\n                                <button class=\"btn btn-primary\">"
    + ((stack1 = ((helper = (helper = helpers.btnText || (depth0 != null ? depth0.btnText : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"btnText","hash":{},"data":data}) : helper))) != null ? stack1 : "")
    + "</button>\n                        </div>\n                    </a>\n                </div>\n";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "<div class=\"swiper-container-outer\">\n    <div class=\"swiper-container swiper-container-process\">\n        <div class=\"swiper-wrapper\">\n"
    + ((stack1 = helpers.each.call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.steps : depth0),{"name":"each","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "        </div>\n        <a class=\"process-swiper-navigate-prev nav-btn ng-icon-directionleft\" href=\"#processCarousel\" data-slide=\"prev\"></a>\n        <a class=\"process-swiper-navigate-next nav-btn ng-icon-directionright\" href=\"#processCarousel\" data-slide=\"next\"></a>\n        <div class=\"process-swiper-pagination\"></div>\n    </div>\n</div>";
},"useData":true});

this["JST"]["register"] = Handlebars.template({"1":function(container,depth0,helpers,partials,data) {
    var helper;

  return "value=\""
    + container.escapeExpression(((helper = (helper = helpers.fname || (depth0 != null ? depth0.fname : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"fname","hash":{},"data":data}) : helper)))
    + "\"";
},"3":function(container,depth0,helpers,partials,data) {
    var helper;

  return "value=\""
    + container.escapeExpression(((helper = (helper = helpers.lname || (depth0 != null ? depth0.lname : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"lname","hash":{},"data":data}) : helper)))
    + "\"";
},"5":function(container,depth0,helpers,partials,data) {
    var helper;

  return "value=\""
    + container.escapeExpression(((helper = (helper = helpers.email || (depth0 != null ? depth0.email : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"email","hash":{},"data":data}) : helper)))
    + "\"";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {});

  return "<i class=\"register-login-forms-close ng-icon-close\"></i>\n<div class=\"formmessage layout-vertical-center\">\n    <div class=\"inner\">\n        <div class=\"modal-title\">\n            Register\n        </div>\n        <div class=\"contentModalWrap\">\n            <p>Please register in order to participate!</p>\n        </div>\n    </div>\n</div>\n<div id=\"register\" class=\"modal-container layout-vertical-center\">\n    <div class=\"inner\">\n        <div class=\"validationMessage\"></div>\n        <!--<div class=\"modal-title\">Register</div>-->\n        <div class=\"form\" role=\"form\">\n            <div class=\"row modal-row-pad\">\n                <div class=\"col-xs-12\">\n                    <div class=\"form-group\">\n                        <input name=\"fname\" type=\"text\" id=\"\" placeholder=\"\" class=\"form-control firstLoad\" required=\"required\" "
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.fname : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + ">\n                        <span class=\"floating-label\">First Name</span>\n                    </div>\n                </div>\n            </div>\n            <div class=\"row modal-row-pad\">\n                <div class=\"col-xs-12\">\n                    <div class=\"form-group\">\n                        <input name=\"lname\" type=\"text\" id=\"\" placeholder=\"\" class=\"form-control\" required=\"required\" "
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.lname : depth0),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + ">\n                        <span class=\"floating-label\">Last Name</span>\n                    </div>\n                </div>\n            </div>\n            <div class=\"row modal-row-pad\">\n                <div class=\"col-xs-12\">\n                    <div class=\"form-group\">\n                        <input name=\"email\" type=\"text\" id=\"\" placeholder=\"\" class=\"form-control\" required=\"required\" "
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.email : depth0),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + ">\n                        <span class=\"floating-label\">Email</span>\n                    </div>\n                </div>\n            </div>\n            <div class=\"row modal-row-pad\">\n                <div class=\"col-xs-12\">\n                    <div class=\"form-group\">\n                        <input name=\"password\" type=\"password\" id=\"\" placeholder=\"\" class=\"form-control\" required=\"required\">\n                        <span class=\"floating-label\">Password</span>\n                    </div>\n                </div>\n            </div>\n            <div class=\"row modal-row-pad\">\n                <div class=\"col-xs-12\" >\n                    <div class=\"form-group checkGroup\">\n                      <div class=\"form-control\">\n                        <input name=\"chkAgree\" id=\"chkAgree\" type=\"checkbox\" required=\"required\">\n                        <label for=\"chkAgree\"></label>\n                        <span>\n                            I accept the <a href=\"/#terms-conditions\">Terms & Conditions</a>\n                        </span>\n                      </div>\n                    </div>\n                    <!-- Hidden Fields -->\n                    <input name=\"type\" type=\"hidden\" />\n                </div>\n            </div>\n            <div class=\"row modal-row-pad\">\n                <div class=\"col-xs-12\">\n                    <button id=\"submitRegisterBtn\" class=\"submitRegisterBtn btn btn-primary\" type=\"submit\" disabled=\"disabled\">Register</button> <span class=\"toggle-span\">or</span> <a href=\"#login\" class=\"btn btn-primary modal-switch\">Login</a>\n                </div>\n            </div>\n            <div class=\"register_message\" style=\"display: none\">\n                <div class=\"col-xs-12\">\n                </div>\n            </div>\n        </div>\n    </div>\n</div>\n\n";
},"useData":true});

this["JST"]["resetpassword"] = Handlebars.template({"1":function(container,depth0,helpers,partials,data) {
    var helper;

  return "value=\""
    + container.escapeExpression(((helper = (helper = helpers.regEmail || (depth0 != null ? depth0.regEmail : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"regEmail","hash":{},"data":data}) : helper)))
    + "\"";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "<div class=\"formmessage layout-vertical-center\">\n    <div class=\"inner\">\n        <div class=\"modal-title\">\n            Reset\n        </div>\n        <div class=\"contentModalWrap\">\n            <p>Please reset your password.</p>\n        </div>\n    </div>\n</div>\n<div id=\"resetPassword\" class=\"modal-container layout-vertical-center\">\n    <div class=\"inner\">\n        <div class=\"validationMessage\"></div>\n        <div class=\"form message\" role=\"form\">\n            <div class=\"row\">\n                <div class=\"col-xs-12\">\n                    <div class=\"form-group\">\n                        <input name=\"email\" type=\"email\" id=\"\" class=\"form-control firstLoad\" "
    + ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.regEmail : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + ">\n                        <span class=\"floating-label\">Email</span>\n                    </div>\n                </div>\n            </div>\n            <div class=\"row\">\n                <div class=\"col-xs-12\">\n                    <div class=\"form-group\">\n                        <input name=\"password\" type=\"password\" class=\"form-control\" autocomplete=\"false\">\n                        <span class=\"floating-label\">Password</span>\n                    </div>\n                </div>\n            </div>\n            <div class=\"row\">\n                <div class=\"col-xs-12\">\n                    <button id=\"resetPasswordButton\" class=\"btn btn-primary\" type=\"submit\" disabled=\"disabled\">Reset Password</button>\n                </div>\n            </div>\n        </div>\n    </div>\n</div>";
},"useData":true});

this["JST"]["scoring"] = Handlebars.template({"1":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3=container.escapeExpression;

  return "                                    <div class=\"tick tickTop\" style=\"left:"
    + alias3((helpers.tickLeft || (depth0 && depth0.tickLeft) || alias2).call(alias1,(data && data.index),((stack1 = (depths[1] != null ? depths[1].items : depths[1])) != null ? stack1.length : stack1),{"name":"tickLeft","hash":{},"data":data}))
    + ";\"></div>\n                                    <div class=\"tick tickBottom\"\n                                         style=\"left:"
    + alias3((helpers.tickLeft || (depth0 && depth0.tickLeft) || alias2).call(alias1,(data && data.index),((stack1 = (depths[1] != null ? depths[1].items : depths[1])) != null ? stack1.length : stack1),{"name":"tickLeft","hash":{},"data":data}))
    + ";\">"
    + alias3(((helper = (helper = helpers.score || (depth0 != null ? depth0.score : depth0)) != null ? helper : alias2),(typeof helper === "function" ? helper.call(alias1,{"name":"score","hash":{},"data":data}) : helper)))
    + "</div>\n";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3="function", alias4=container.escapeExpression;

  return "<div class=\"scoreQuestion section pushSection solid-gray-bg scoreQuestionCount\" data-title=\"\">\n    <div class=\"container\" data-title=\"\">\n        <div class=\"row\">\n            <div class=\"col-md-offset-1 col-md-10\">\n                <div class=\"content\">\n                    <div class=\"inner\">\n                        <a class=\"sbtn scoreCommentBtn primaryBlankBtn hidden-xs\" status=\""
    + alias4(((helper = (helper = helpers.statusScore || (depth0 != null ? depth0.statusScore : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"statusScore","hash":{},"data":data}) : helper)))
    + "\">Leave\n                            Comments</a>\n                        <h3>"
    + alias4(((helper = (helper = helpers.title || (depth0 != null ? depth0.title : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"title","hash":{},"data":data}) : helper)))
    + " <span class=\"scoreRange\">(0-"
    + alias4((helpers.minusOne || (depth0 && depth0.minusOne) || alias2).call(alias1,((stack1 = (depth0 != null ? depth0.items : depth0)) != null ? stack1.length : stack1),{"name":"minusOne","hash":{},"data":data}))
    + ")</span></h3>\n                        <div class=\"text-center\">\n                            <div class=\"helpText\">\n                                <div class=\"inner\"><h5>Help</h5>"
    + alias4(((helper = (helper = helpers.helpText || (depth0 != null ? depth0.helpText : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"helpText","hash":{},"data":data}) : helper)))
    + "</div>\n                            </div>\n                            <div class=\"commentsText text-center\">\n                    <textarea class=\"txtComment\"\n                              placeholder=\"Comments (minimum 50 characters): Explain your score and provide feedback to the participant.\"></textarea>\n                                <div class=\"hidden-xs\">\n                                    <a class=\"btn\" style=\"display: none\" href=\"#\">Submit Comments</a>\n                                </div>\n                            </div>\n                        </div>\n                        <div class=\"scoreHeader\">\n                            <div class=\"scoreQuestion\">\n                                <span class=\"hidden\">"
    + alias4(((helper = (helper = helpers.title || (depth0 != null ? depth0.title : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"title","hash":{},"data":data}) : helper)))
    + "</span>\n                                <span class=\"question-text\">"
    + alias4(((helper = (helper = helpers.question || (depth0 != null ? depth0.question : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"question","hash":{},"data":data}) : helper)))
    + "</span>\n                                <span class=\"help-question hidden-xs\">\n						<div class=\"icon-icon-help1\"></div>\n					</span>\n                                <div class=\"icon-icon-close\"></div>\n                            </div>\n                        </div>\n                        <div class=\"scoreDescription\">\n                            <span></span>\n                        </div>\n                        <div class=\"scoreValue\">\n                            <span class=\"valfollow\" status=\""
    + alias4(((helper = (helper = helpers.statusScore || (depth0 != null ? depth0.statusScore : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"statusScore","hash":{},"data":data}) : helper)))
    + "\"></span>\n                            <span class=\"valBorderLeft\"></span>\n                            <span class=\"valBorderRight\"></span>\n                        </div>\n                        <div class=\"scoreSliderContainer\">\n                            <div class=\"sliderTicks\">\n                                <span class=\"visible-xs anchorLow\">"
    + alias4(((helper = (helper = helpers.anchorLow || (depth0 != null ? depth0.anchorLow : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"anchorLow","hash":{},"data":data}) : helper)))
    + "</span>\n"
    + ((stack1 = helpers.each.call(alias1,(depth0 != null ? depth0.items : depth0),{"name":"each","hash":{},"fn":container.program(1, data, 0, blockParams, depths),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "                                <span class=\"visible-xs anchorHigh\">"
    + alias4(((helper = (helper = helpers.anchorHigh || (depth0 != null ? depth0.anchorHigh : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"anchorHigh","hash":{},"data":data}) : helper)))
    + "</span>\n                            </div>\n                            <span class=\"traitEndL\">"
    + alias4(((helper = (helper = helpers.anchorLow || (depth0 != null ? depth0.anchorLow : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"anchorLow","hash":{},"data":data}) : helper)))
    + "</span>\n                            <div class=\"scoreSlider\">\n                                <input id=\"ex"
    + alias4(((helper = (helper = helpers.index || (depth0 != null ? depth0.index : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"index","hash":{},"data":data}) : helper)))
    + "\" class=\"scoreSliderInput\" data-slider-id=\"ex"
    + alias4(((helper = (helper = helpers.index || (depth0 != null ? depth0.index : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"index","hash":{},"data":data}) : helper)))
    + "Slider\"\n                                       type=\"text\"\n                                       data-slider-min=\"0\" data-slider-max=\""
    + alias4((helpers.minusOne || (depth0 && depth0.minusOne) || alias2).call(alias1,((stack1 = (depth0 != null ? depth0.items : depth0)) != null ? stack1.length : stack1),{"name":"minusOne","hash":{},"data":data}))
    + "\"\n                                       data-slider-step=\"0.1\"\n                                       data-slider-value=\""
    + alias4(((helper = (helper = helpers.initScore || (depth0 != null ? depth0.initScore : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"initScore","hash":{},"data":data}) : helper)))
    + "\"/>\n                            </div>\n                            <span class=\"traitEndR\">"
    + alias4(((helper = (helper = helpers.anchorHigh || (depth0 != null ? depth0.anchorHigh : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"anchorHigh","hash":{},"data":data}) : helper)))
    + "</span>\n                        </div>\n                    </div>\n                </div>\n            </div>\n        </div>\n    </div>\n</div>\n";
},"useData":true,"useDepths":true});

this["JST"]["sidebar"] = Handlebars.template({"1":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = container.invokePartial(partials.sidebaritem,depth0,{"name":"sidebaritem","data":data,"indent":"\t\t","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "");
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "<div class=\"sidebar-item-wrap\">\n"
    + ((stack1 = helpers.each.call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.menuItems : depth0),{"name":"each","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "</div>\n<div class=\"sidebar-social\">\n	<span class=\"txt\">Follow us</span>\n	<div class=\"links-wrap\">\n		<a href=\"https://www.facebook.com/InsideNatGeo/\" target=\"_blank\">\n    		<span class=\"ng-icon-facebook\"></span>\n    	</a>\n    	<a href=\"https://twitter.com/InsideNatGeo/\" target=\"_blank\">\n    		<span class=\"ng-icon-twitter\"></span>\n    	</a>\n	</div>\n</div>";
},"usePartial":true,"useData":true});

this["JST"]["sidenav"] = Handlebars.template({"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "<div class=\"sideNav\">\n    <span class=\"prev nav-btn ng-icon-directionleft\"></span>\n    <span class=\"counter layout-vertical-center\">\n    	<div class=\"counter-inner\">\n        	<div class=\"currentCount\">1</div>\n        	<span class=\"divider\"></span>\n        	<div class=\"totalCount\">"
    + container.escapeExpression(container.lambda(((stack1 = (depth0 != null ? depth0.records : depth0)) != null ? stack1.length : stack1), depth0))
    + "</div>\n        </div>\n    </span>\n    <span class=\"next nav-btn ng-icon-directionright\"></span>\n</div>";
},"useData":true});

this["JST"]["social"] = Handlebars.template({"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3="function", alias4=container.escapeExpression;

  return "<li><div class=\"socialicon socialicon-"
    + alias4(((helper = (helper = helpers.key || (depth0 != null ? depth0.key : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"key","hash":{},"data":data}) : helper)))
    + "\"></div><a href=\""
    + alias4(((helper = (helper = helpers.url || (depth0 != null ? depth0.url : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"url","hash":{},"data":data}) : helper)))
    + "\" target=\"_blank\">"
    + alias4(((helper = (helper = helpers.name || (depth0 != null ? depth0.name : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"name","hash":{},"data":data}) : helper)))
    + "</a></li>";
},"useData":true});

this["JST"]["timeline"] = Handlebars.template({"1":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = container.invokePartial(partials.timelineinfo,depth0,{"name":"timelineinfo","data":data,"indent":"                                    ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "");
},"3":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3="function", alias4=container.escapeExpression;

  return "                                <div class=\"timelinePoint "
    + ((stack1 = helpers["if"].call(alias1,(data && data.first),{"name":"if","hash":{},"fn":container.program(4, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + " "
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.active : depth0),{"name":"if","hash":{},"fn":container.program(6, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + " "
    + ((stack1 = helpers["if"].call(alias1,(data && data.last),{"name":"if","hash":{},"fn":container.program(8, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\"\n                                     data-toggle=\"tooltip\" data-placement=\"top\" data-html=\"true\" data-trigger=\"hover\"\n                                     title=\"<span>"
    + alias4(((helper = (helper = helpers.title || (depth0 != null ? depth0.title : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"title","hash":{},"data":data}) : helper)))
    + "</span><span class='sub'>"
    + ((stack1 = ((helper = (helper = helpers.balloonsubtitle || (depth0 != null ? depth0.balloonsubtitle : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"balloonsubtitle","hash":{},"data":data}) : helper))) != null ? stack1 : "")
    + "</span>\"\n                                     style=\"left: "
    + alias4(((helper = (helper = helpers.leftPercentage || (depth0 != null ? depth0.leftPercentage : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"leftPercentage","hash":{},"data":data}) : helper)))
    + "%;\">\n                                </div>\n                                <div class=\"connectLine\" data-period=\""
    + alias4(((helper = (helper = helpers.period || (depth0 != null ? depth0.period : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"period","hash":{},"data":data}) : helper)))
    + "\" style=\"left: "
    + alias4(((helper = (helper = helpers.leftPercentage || (depth0 != null ? depth0.leftPercentage : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"leftPercentage","hash":{},"data":data}) : helper)))
    + "%; width: "
    + alias4(((helper = (helper = helpers.connectLineLWidthPercentage || (depth0 != null ? depth0.connectLineLWidthPercentage : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"connectLineLWidthPercentage","hash":{},"data":data}) : helper)))
    + "%;\">\n                                </div>                               \n";
},"4":function(container,depth0,helpers,partials,data) {
    return "first";
},"6":function(container,depth0,helpers,partials,data) {
    return "active";
},"8":function(container,depth0,helpers,partials,data) {
    return "last";
},"10":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3="function", alias4=container.escapeExpression;

  return "                                    <li style=\"width: "
    + alias4(((helper = (helper = helpers.widthPercentage || (depth0 != null ? depth0.widthPercentage : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"widthPercentage","hash":{},"data":data}) : helper)))
    + "%\" title=\""
    + alias4(((helper = (helper = helpers.name || (depth0 != null ? depth0.name : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"name","hash":{},"data":data}) : helper)))
    + "\" data-period=\""
    + alias4(((helper = (helper = helpers.name || (depth0 != null ? depth0.name : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"name","hash":{},"data":data}) : helper)))
    + "\">"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.name : depth0),{"name":"if","hash":{},"fn":container.program(11, data, 0),"inverse":container.program(13, data, 0),"data":data})) != null ? stack1 : "")
    + "</li>\n";
},"11":function(container,depth0,helpers,partials,data) {
    var helper;

  return container.escapeExpression(((helper = (helper = helpers.name || (depth0 != null ? depth0.name : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"name","hash":{},"data":data}) : helper)));
},"13":function(container,depth0,helpers,partials,data) {
    return "&nbsp";
},"15":function(container,depth0,helpers,partials,data) {
    return "                        <div class=\"timeline-toggle\">\n                            <div class=\"detail\">\n                                <i class=\"material-icons\">list</i> detail view\n                            </div>\n                            <div class=\"overview\">\n                                <i class=\"material-icons\">apps</i> overview\n                            </div>\n                        </div>\n";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {});

  return "<div class=\"container\" data-title=\"\">\n    <div class=\"row\">\n        <div class=\"col-md-offset-1 col-md-10\">\n            <div class=\"content\">\n                <div class=\"timelineContainer\">\n                    <div id=\"timelineInfo\" class=\"timelineInfoContainer carousel slide layout-vertical-center\"\n                         data-interval=\"false\">\n                        <div class=\"timelineWrap\">\n                            <div class=\"carousel-inner timelineInfo\" role=\"listbox\">\n"
    + ((stack1 = helpers.each.call(alias1,(depth0 != null ? depth0.timelinePoints : depth0),{"name":"each","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "                            </div>\n                        </div>\n                    </div>\n                    <div class=\"timeline-bottom\">\n                        <div class=\"timeline\">\n"
    + ((stack1 = helpers.each.call(alias1,(depth0 != null ? depth0.timelinePoints : depth0),{"name":"each","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "                        </div>\n\n                        <div class=\"timeline-periods\">\n                            <ul class=\"clearfix\">\n"
    + ((stack1 = helpers.each.call(alias1,(depth0 != null ? depth0.timelinePeriods : depth0),{"name":"each","hash":{},"fn":container.program(10, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "                            </ul>\n                        </div>\n                        <div class=\"timeline-calendar\">\n                            <ul></ul>\n                        </div>\n                        <div class=\"timeline-period hidden\"><h3></h3><h6></h6></div>\n\n                        <!-- this needs to be structure into the new style-->\n                        <div class=\"timeline-legend\">\n                            <ul class=\"tl-legend\">\n                                <li><span class=\"prize-launch\"></span> Prize Launch</li>\n                                <li><span class=\"upcoming-milestone\"></span> Upcoming Milestone</li>\n                                <li><span class=\"milestones\"></span> Milestones</li>\n                                <li><span class=\"prize-completes\"></span> Prize Completes</li>\n                            </ul>\n                        </div>\n                    </div>\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.showOverview : depth0),{"name":"if","hash":{},"fn":container.program(15, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "                </div>\n            </div>\n        </div>\n    </div>\n</div>\n\n<a class=\"timelineNav carousel-control left hidden-xs hidden-sm nav-btn ng-icon-directionleft\"\n   data-slide=\"prev\" data-target=\"#timelineInfo\"></a>\n<a class=\"timelineNav carousel-control right hidden-xs hidden-sm nav-btn ng-icon-directionright\"\n   data-slide=\"next\" data-target=\"#timelineInfo\"></a>\n";
},"usePartial":true,"useData":true});

this["JST"]["validationmessage"] = Handlebars.template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression;

  return "    <i class=\"register-login-forms-close icon-icon-close\"></i>\n    <div class=\"formmessage layout-vertical-center\">\n        <div class=\"inner\">\n            <div class=\"modal-title\">\n                "
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.customActionPanel : depth0)) != null ? stack1.title : stack1), depth0))
    + "\n            </div>\n            <div class=\"contentModalWrap\">\n                <p>"
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.customActionPanel : depth0)) != null ? stack1.message : stack1), depth0))
    + "</p>\n            </div>\n        </div>\n    </div>\n";
},"3":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.hasError : depth0),{"name":"if","hash":{},"fn":container.program(4, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "");
},"4":function(container,depth0,helpers,partials,data) {
    return "                    <a class=\"btn btn-primary \" href=\"#login\">Back to Login</a>\n";
},"6":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.hasError : depth0),{"name":"if","hash":{},"fn":container.program(7, data, 0),"inverse":container.program(7, data, 0),"data":data})) != null ? stack1 : "");
},"7":function(container,depth0,helpers,partials,data) {
    return "                    <a class=\"btn btn-primary \" href=\"#login\">Login</a>\n";
},"9":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.hasError : depth0),{"name":"if","hash":{},"fn":container.program(10, data, 0),"inverse":container.program(7, data, 0),"data":data})) != null ? stack1 : "");
},"10":function(container,depth0,helpers,partials,data) {
    return "                    <a class=\"btn btn-primary \" href=\"#register\">Register</a>\n";
},"12":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.hasError : depth0),{"name":"if","hash":{},"fn":container.program(13, data, 0),"inverse":container.program(7, data, 0),"data":data})) != null ? stack1 : "");
},"13":function(container,depth0,helpers,partials,data) {
    return "\n";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3="function";

  return ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.customActionPanel : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\n<div id=\"form-message\" class=\"modal-container layout-vertical-center\">\n    <div class=\"inner\">\n        <div class=\"contentModalWrap\">\n            <p>"
    + ((stack1 = ((helper = (helper = helpers.messageTitle || (depth0 != null ? depth0.messageTitle : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"messageTitle","hash":{},"data":data}) : helper))) != null ? stack1 : "")
    + "</p>\n            <p>"
    + ((stack1 = ((helper = (helper = helpers.message || (depth0 != null ? depth0.message : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"message","hash":{},"data":data}) : helper))) != null ? stack1 : "")
    + "</p>\n"
    + ((stack1 = (helpers.ifCond || (depth0 && depth0.ifCond) || alias2).call(alias1,(depth0 != null ? depth0.originForm : depth0),"login",{"name":"ifCond","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + ((stack1 = (helpers.ifCond || (depth0 && depth0.ifCond) || alias2).call(alias1,(depth0 != null ? depth0.originForm : depth0),"register",{"name":"ifCond","hash":{},"fn":container.program(6, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + ((stack1 = (helpers.ifCond || (depth0 && depth0.ifCond) || alias2).call(alias1,(depth0 != null ? depth0.originForm : depth0),"forgotPassword",{"name":"ifCond","hash":{},"fn":container.program(9, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + ((stack1 = (helpers.ifCond || (depth0 && depth0.ifCond) || alias2).call(alias1,(depth0 != null ? depth0.originForm : depth0),"resetPassword",{"name":"ifCond","hash":{},"fn":container.program(12, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "        </div>\n    </div>\n</div>\n\n\n";
},"useData":true});

this["JST"]["404"] = Handlebars.template({"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    return "<div class=\"missingPageInner\">\n    <div class=\"container\">\n        <div class=\"row layout-vertical-center\">\n        	<div class=\"col-md-10 col-md-offset-1 col-lg-10 col-lg-offset-1\">\n				<div class=\"retired-load_404_card1 layout-load-card\">\n					<h1><span>404 Error<span></h1>\n					<p>The page you are looking for is no longer here, or maybe it never was here in the first place. In any case, we’re sorry you were sent on this mission far from home.</p>\n				</div>\n				<a href=\"#home\" class=\"btn\">Take Me Back Home</a>\n            </div>\n        </div>\n    </div>\n</div>";
},"useData":true});

this["JST"]["sharedcard"] = Handlebars.template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {});

  return "            <div class=\"cardWrap border-box\" data-id=\""
    + container.escapeExpression(((helper = (helper = helpers.id || (depth0 != null ? depth0.id : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(alias1,{"name":"id","hash":{},"data":data}) : helper)))
    + "\" data-pagenav=\"true\">\n                <!-- news -->\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.category : depth0),{"name":"if","hash":{},"fn":container.program(2, data, 0),"inverse":container.program(4, data, 0),"data":data})) != null ? stack1 : "")
    + "                <!-- end row detail -->\n            </div>\n            <!-- end cardWrap -->\n";
},"2":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3="function", alias4=container.escapeExpression;

  return "                    <div class=\"imageWrapLarge news-sidesocial-container layout-vertical-center hidden-xs hidden-sm\">\n                        <div>\n                            <div class=\"news-detail-image\">\n                                <div class=\"inner-image\" style=\"background-image: url("
    + alias4(((helper = (helper = helpers.image || (depth0 != null ? depth0.image : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"image","hash":{},"data":data}) : helper)))
    + ")\"></div>\n                            </div>\n                            <div class=\"news-detail-share-container layout-vertical-center\">\n                                <div class=\"inner-share\">\n                                    <span class=\"share\">Share</span>\n                                    <i class=\"icon-icon-facebook shareSocial\" data-action=\"facebook\"\n                                       data-title=\""
    + alias4(((helper = (helper = helpers.h3 || (depth0 != null ? depth0.h3 : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"h3","hash":{},"data":data}) : helper)))
    + "\"></i>\n                                    <i class=\"icon-icon-twitter shareSocial\" data-action=\"twitter\"\n                                       data-title=\""
    + alias4(((helper = (helper = helpers.h3 || (depth0 != null ? depth0.h3 : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"h3","hash":{},"data":data}) : helper)))
    + "\"></i>\n                                </div>\n                            </div>\n                        </div>\n                    </div>\n\n                    <div class=\"row detail\">\n                        <div class=\"col-md-12 tplContentContainer\">\n                            <div class=\"inner-scrollable news-inner-scrollable\">\n                                <div class=\"content-container\">\n                                    <div class=\"visible-xs visible-sm\">\n                                        <div class=\"imageWrapLarge news-sidesocial-container\">\n                                            <div class=\"news-detail-image\">\n                                                <div class=\"inner-image\" style=\"background-image: url("
    + alias4(((helper = (helper = helpers.image || (depth0 != null ? depth0.image : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"image","hash":{},"data":data}) : helper)))
    + ")\"></div>\n                                            </div>\n                                        </div>\n                                    </div>\n                                    <span class=\"newsTypeTag\">"
    + alias4(((helper = (helper = helpers.category || (depth0 != null ? depth0.category : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"category","hash":{},"data":data}) : helper)))
    + "</span>\n                                    <span class=\"cardWrapDate\">"
    + alias4(((helper = (helper = helpers.span || (depth0 != null ? depth0.span : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"span","hash":{},"data":data}) : helper)))
    + "</span>\n                                    <h4 class=\"name\">"
    + alias4(((helper = (helper = helpers.h3 || (depth0 != null ? depth0.h3 : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"h3","hash":{},"data":data}) : helper)))
    + "</h4>\n                                    <div class=\"content\">"
    + ((stack1 = ((helper = (helper = helpers.content || (depth0 != null ? depth0.content : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"content","hash":{},"data":data}) : helper))) != null ? stack1 : "")
    + "</div>\n                                </div>\n                            </div>\n                        </div>\n                    </div>\n";
},"4":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3="function", alias4=container.escapeExpression;

  return "                    <!-- judges -->\n\n                    <div class=\"imageWrapLarge layout-vertical-center hidden-xs hidden-sm\">\n                        <div>\n                            <div class=\"judges-detail-image\">\n                                <div class=\"inner-image\" style=\"background-image: url("
    + alias4(((helper = (helper = helpers.image || (depth0 != null ? depth0.image : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"image","hash":{},"data":data}) : helper)))
    + ")\"></div>\n                            </div>\n                        </div>\n                    </div>\n\n                    <div class=\"row detail\">\n                        <div class=\"col-md-12 tplContentContainer\">\n                            <div class=\"inner-scrollable\">\n                                <div class=\"content-container\">\n                                    <div class=\"visible-xs visible-sm\">\n                                        <div class=\"imageWrapLarge judges-img-mobile-container\">\n                                            <div class=\"judges-detail-image\">\n                                                <div class=\"inner-image\" style=\"background-image:url("
    + alias4(((helper = (helper = helpers.image || (depth0 != null ? depth0.image : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"image","hash":{},"data":data}) : helper)))
    + ");\"></div>\n                                            </div>\n                                        </div>\n                                    </div>\n\n                                    <span class=\"judgeGroupSpan\">"
    + alias4(((helper = (helper = helpers.type || (depth0 != null ? depth0.type : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"type","hash":{},"data":data}) : helper)))
    + "</span>\n                                    <h4 class=\"name\">"
    + alias4(((helper = (helper = helpers.h3 || (depth0 != null ? depth0.h3 : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"h3","hash":{},"data":data}) : helper)))
    + "</h4>\n                                    <h6 class=\"title\">"
    + alias4(((helper = (helper = helpers.h4 || (depth0 != null ? depth0.h4 : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"h4","hash":{},"data":data}) : helper)))
    + "</h6>\n                                    <div class=\"content\">"
    + ((stack1 = ((helper = (helper = helpers.content || (depth0 != null ? depth0.content : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"content","hash":{},"data":data}) : helper))) != null ? stack1 : "")
    + "</div>\n                                </div>\n                            </div>\n                        </div>\n                    </div>\n\n\n";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3="function", alias4=container.escapeExpression;

  return "<div class=\"tplWrapper\">\n    <div class=\"tplNavWrapper\">\n        <div class=\"navWrapSocialContainer visible-xs visible-sm\">\n            <div>\n                <i class=\"icon-icon-facebook shareSocial\" data-action=\"facebook\" data-title=\""
    + alias4(((helper = (helper = helpers.h3 || (depth0 != null ? depth0.h3 : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"h3","hash":{},"data":data}) : helper)))
    + "\"></i>\n                <i class=\"icon-icon-twitter shareSocial\" data-action=\"twitter\" data-title=\""
    + alias4(((helper = (helper = helpers.h3 || (depth0 != null ? depth0.h3 : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"h3","hash":{},"data":data}) : helper)))
    + "\"></i>\n            </div>\n        </div>\n        <i class=\"icon-close close\"></i>\n        <div class=\"sideNavWrap\" data-scrollparent=\".tplWrapper\"></div>\n    </div>\n    <div class=\"cardWrapper\">\n"
    + ((stack1 = helpers.each.call(alias1,(depth0 != null ? depth0.records : depth0),{"name":"each","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "    </div>\n</div>\n";
},"useData":true});

if (typeof exports === 'object' && exports) {module.exports = this["JST"];}

return this["JST"];

});
