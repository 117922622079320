// This is a duplicate of the config.js, which tends to have a class name config with certain build processes
// TODO - Set this up as a symlink or temp duplicate it as part of the build process
define('app/configoptions',['jquery','jquery.livequery'],function($){

    // All the "count" config selectors we will need to monitor
    var countSelectors = [
        '.daysCount',
        '.dayCount',
        '.daysLeft',
        '.dayLeft',
        '.newsCount',
        '.data-newsCount',
        '.dayCountWord',
        '.dayLeftCountWord'
    ];
    // Default (hardcoded) configuration values
    var defaultConfig = (window.rampitConfig || {
    	// for content pulling
        baseUrl: 'https://dev.marineprotectionprize.com',
        rampitRegTimeLeft: 90,
        rampitRegTimeFrame: 'Days',
        rampitSubTimeLeft: 180,
        rampitSubTimeFrame: 'Days',
        showRegisterLogin: true,
        newsCount: 6,
        regConfig: {
            type: "",
            closeTitle: "Test Title",
            closeMessage: "Test Message",
            email: "",
            fname: "",
            lname: ""
        },
        urls: {
            judges: 'data/judges.json',
            news: 'data/news.json',
            sidebar: 'data/sidebar.json',
            navigationmap: 'data/navigationmap.json',
            dashboardlinks: 'data/dashboardlinks.json',
            competitions: 'data/competitions.json',
            people: 'data/people.json',
            scoring: 'data/scoring.json',
            resources: 'data/resources.json',
            sponsors: 'data/sponsors.json',
            process: 'data/process.json',
            timeline: 'data/timeline.json',
            htmlcontent: 'data/htmlcontent.json',
            events: 'data/events.json',
            problemstatement: 'data/problemstatement.json',
            faqs: 'data/faqs.json',
            leadership: 'data/leadership.json'
        }
    });
    // Configuration methods, to be applied to the "config" class / require module
    var configMethods = {
        refresh: function() {
            $('.daysCount, .dayCount').text(defaultConfig.rampitRegTimeLeft);
            $('.dayCountWord').text(defaultConfig.rampitRegTimeFrame);
            $('.daysLeft, .dayLeft').text(defaultConfig.rampitSubTimeLeft);
            $('.dayLeftCountWord').text(defaultConfig.rampitSubTimeFrame);
            $('.newsCount, .data-newsCount').text(defaultConfig.newsCount);
            // Update navbar text
            if(defaultConfig.rampitRegTimeLeft < 1 && defaultConfig.rampitRegTimeFrame.length > 0) {
                $('#mainNavbar a[href*="register"]').html(defaultConfig.rampitRegTimeFrame).attr('href','#login');
            }
        }
    };
    // Using jquery livequery, watch for changes in specific elements and refresh config
    $('body').livequery(countSelectors.join(','),function(){
        configMethods.refresh();
    });
    // Return and apply the above
    return $.extend({},defaultConfig,configMethods);
});
