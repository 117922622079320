define('modules/judgespreview/basic/js/base.js',["app/config","app/util","jquery","templates","app/module","bootstrap/carousel","jquery.jcarousel"], function(config,util,$,templates,module) {
    return new module({
        name: 'judgespreview',
        selectors: '.judgesPreviewWrap',
        remoteData: [config.urls.judges],

        routes: [{
            route: 'home',
            fn: 'refreshJudgesPreview',
            extra: true,
            delay: 2500
        }],

        initialize: function(data) {
            this.renderTemplates(data);
        },

        onRender: function() {
            this.handleJudgeNavigation();
            this.initCarousels();
            $(window).smartresize($.proxy(this.refreshJudgesPreview,this));
            this.refreshJudgesPreview();
        },

        methods: {
            renderTemplates: function(data) {
                $(this.getSelectors()).html(templates.judgespreview($.grep(data,function(record){
                    return (record['quote'] && record['quote'].length > 1);
                })));
            },
            initCarousels: function() {
                $(this.getSelectors()).find('.carousel').carousel({
                    interval: false,
                    wrap: true
                });
                $(this.getSelectors()).find('.thumbSlider').jcarousel({
                    wrap: 'circular',
                    center: false
                });
            },
            refreshJudgesPreview: function() {
                $(this.getSelectors()).find('.thumbSlider').jcarousel('reload');
            },
            handleJudgeNavigation: function() {
                $(this.getSelectors()).find('.judgebox').on('click',$.proxy(function(e){
                    e.preventDefault();
                    var judgeId = $(e.currentTarget).attr('data-judgeid'),
                        slide = $(this.getSelectors()).find('.carousel .item[data-judgeid="'+judgeId+'"]');
                    $(this.getSelectors()).find('.carousel').carousel($(slide).index()); // This relays to the below event listener
                },this));
                $(this.getSelectors()).find('.carousel').on('slide.bs.carousel',$.proxy(function(e){
                    var judgeId = $(e.relatedTarget).attr('data-judgeid'),
                        judgeBox = $(this.getSelectors()).find('.judgebox[data-judgeid="'+judgeId+'"]');
                    $(this.getSelectors()).find('.thumbSlider').jcarousel('scroll',$(judgeBox));
                    $(this.getSelectors()).find('.judgebox').removeClass('active');
                    $(judgeBox).addClass('active');
                },this));
            }
        }
    });
});
